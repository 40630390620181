<template>
  <div class="mapWrapper">
    <div class="commonMap" :id="id"></div>
    <div
      class="tool-box-wrapper"
      :style="{ right: dynamicStyle + 'rem', opacity: toolshow ? '1' : '0' }"
    >
      <div
        v-for="(tool, index) in localConfig.tools"
        class="tool-box"
        :key="index"
        @click.stop="toolHandler(tool)"
        :style="
          (tool === 'map_mark' && showMore.map_mark) ||
          (tool === 'hierarchy' && showMore.hierarchy) ||
          (tool === 'command' && showMore.command) ||
          (tool === 'search' && showMore.search) ||
          (tool === 'measure' && showMore.measure) ||
          (tool === 'marker' && showMore.marker)
            ? 'opacity: 1;'
            : ''
        "
      >
        <img :src="icons[tool]" />
        <div
          class="more-actions"
          v-if="tool === 'hierarchy'"
          v-show="showMore.hierarchy"
        >
          <div class="more-actions-module mask-slider">
            <Row type="flex" align="middle">
              <Col span="4"><img :src="icons['mask']" /></Col>
              <Col span="20">
                <Slider
                  v-model="maskOpacity"
                  show-tip="never"
                  :min="30"
                  @on-input="changeMask"
                ></Slider>
              </Col>
            </Row>
          </div>
          <div class="more-actions-module tile">
            <label class="act-line">
              <input type="radio" v-model="mapLayer" value="平面" />
              <span>平面地图</span>
            </label>
            <label class="act-line">
              <input type="radio" v-model="mapLayer" value="卫星" />
              <span>卫星地图</span>
            </label>
          </div>
          <div class="more-actions-module tile">
            <label class="act-line" v-if="$online">
              <input type="checkbox" value="路网" v-model="mapArea" />
              <span>路网</span>
            </label>
            <label class="act-line">
              <input type="checkbox" value="地名" v-model="mapArea" />
              <span>地名</span>
            </label>
            <label class="act-line">
              <input type="checkbox" value="虚拟护栏" v-model="mapArea" />
              <span>虚拟护栏</span>
            </label>
          </div>
          <div class="more-actions-module">
            <label class="act-line" v-if="$online">
              <input type="checkbox" value="航线" v-model="mapLine" />
              <span>航线</span>
            </label>
            <label class="act-line">
              <input type="checkbox" value="全景照片" v-model="mapPanorama" />
              <span>全景照片</span>
            </label>
            <label class="act-line">
              <input type="checkbox" value="二维正射" v-model="mapTwoDMark" />
              <span>二维正射</span>
            </label>
            <label class="act-line">
              <input type="checkbox" value="三维模型" v-model="mapThreeDMark" />
              <span>三维模型</span>
            </label>
          </div>
        </div>
        <div
          class="more-actions measureBox"
          v-if="tool === 'measure'"
          v-show="showMore.measure"
        >
          <div
            @click.stop="stadiometryFn"
            :style="{ opacity: measureType === 'line' ? '1' : '0.6' }"
          >
            <span>测距</span>
          </div>
          <div
            @click.stop="mSurfaceFn"
            :style="{ opacity: measureType === 'area' ? '1' : '0.6' }"
          >
            <span>测面</span>
          </div>
        </div>
      </div>
    </div>
    <!--搜索框-->
    <div class="searchBox" v-show="showMore.search && $online">
      <input
        v-model="searchMsg"
        id="mapSearch_0"
        autocomplete="off"
        placeholder="地点搜索"
      />
    </div>
    <!--返回图标-->
    <div
      class="goBack pointer"
      @click="$router.back(-1)"
      v-if="mapFlyingParams && mapFlyingParams.pathArr"
    >
      <img src="../../../assets/img/Icon/goBack.png" />
    </div>
    <!--3D切换-->
    <!-- <div class="switch-3d flex pointer"
         v-if="$public"
         v-show="config.show3DSwitchBtn"
         style="right:3rem"
         @click="switch3D">
      <img src="../../../assets/img/statistics/switch.png" />
    </div> -->
    <!--发送目标点-->
    <div
      class="sendCommander"
      v-show="showMore.markerModal"
      v-if="config.onLineList"
      @click.stop=""
    >
      <div class="top">任务标记</div>
      <div class="no-data" v-if="!config.onLineList.length">
        暂时没有无人机在线!
      </div>
      <div class="coordinate">
        <p>地理位置</p>
        经度 <span>{{ sendCommanderOptions.local_data[0].lng }}</span
        ><br />
        纬度
        <span>{{ sendCommanderOptions.local_data[0].lat }}</span>
      </div>
      <div class="mark_info coordinate">
        <p>标注信息</p>
        <div>名称<input v-model="targetName" /></div>
        <div>
          <label>备注</label>
          <textarea v-model="targetRemarks" />
        </div>
      </div>
      <div class="mark_style coordinate">
        <p>标注样式</p>
        <div>
          图标
          <div class="target-icon">
            <img :src="targetIcon" alt="" />
          </div>
          <button @click="changeType = !changeType">更换</button>
          <ul v-show="changeType" class="icons" @click.stop="">
            <li>
              <img
                :src="navigation"
                alt="navigation"
                @click.stop="targetType(navigation, 'mark_state_navigation')"
              />
            </li>
            <li>
              <img
                :src="doubt"
                alt="doubt"
                @click.stop="targetType(doubt, 'mark_state_doubt')"
              />
            </li>
            <li>
              <img
                :src="set"
                alt="set"
                @click.stop="targetType(set, 'mark_state_set')"
              />
            </li>
            <li>
              <img
                :src="prohibit"
                alt="prohibit"
                @click.stop="targetType(prohibit, 'mark_state_prohibit')"
              />
            </li>
            <li>
              <img
                :src="warning"
                alt="warning"
                @click.stop="targetType(warning, 'mark_state_warning')"
              />
            </li>
          </ul>
        </div>
        <div class="btns">
          <div
            class="sendBtn"
            :loading="sendCommanderOptions.loading"
            @click.stop="submitSendCommander"
          >
            确定
          </div>
          <div class="sendBtn sendCancel" @click.stop="cancelSendCommander">
            取消
          </div>
        </div>
      </div>
    </div>
    <!--群体飞控-->
    <!-- <div style="opacity: 0; position: absolute; bottom: 0">
      <div class="sendControlMsg"
           ref="sendControlMsg">
        <i class="ivu-icon ivu-icon-ios-close-circle-outline"
           @click="cancelSendControlbox"></i>
        <Button type="primary"
                size="small"
                @click="sendControlMsgFn">进行远程控制
        </Button>
        <h1>将对以下飞手发送远程控制请求:</h1>

        <div class="dronePilotBox">
          <div class="dronePilot"
               v-for="(item, ind) in UAVSelectedList"
               :key="ind">
            <img class="headImg"
                 :src="item.head_icon_path"
                 alt="" />
            <div class="Divider"></div>
            <div class="msgBox">
              <h2>{{ item.name }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 名称显隐 -->
    <!-- <Checkbox v-if="config.isLive"
              class="show_name"
              :style="'right:' + dynamicStyle + 'px'"
              border
              @on-change="changeName"
              :true-value="'visible'"
              :false-value="'hidden'"
              v-model="showName">显示名称
    </Checkbox> -->
    <!-- <Modal v-model="ControlModal"
           width="600"
           title="控制台"
           footer-hide
           draggable
           @on-cancel="cancelSendControlMsg"
           class-name="ControlModal1">
      <ControlModal :info="config.onLineList"
                    :UAVSelectedList="UAVSelectedList"
                    @sendSocketMsg="sendSocketMsg"></ControlModal>
    </Modal> -->
    <!-- 地图标注面板 -->
    <div class="tagging" v-if="drawing">
      <!-- 标注列表 -->

      <ul>
        <p>
          工具
          <Icon
            type="md-close"
            color="#fff"
            size="22"
            style="float: right; cursor: pointer"
            @click="closeDrawing"
          />
        </p>
        <!-- <li>
            <Button type="primary"
                    v-show="!drawing"
                    @click="onMark">启用标注</Button>
            <Button type="warning"
                    @click="offMark"
                    v-show="drawing"
                    :disabled="!drawed">取消标注</Button>
          </li> -->
        <p>标注列表</p>
        <Select
          v-model="markID"
          style="width: 200px; margin: 0 0 10px 10px"
          clearable
          label-in-value
          :disabled="!drawed"
          @on-change="changeMark"
        >
          <Option v-for="item in mapMarkList" :value="item.id" :key="item.id">{{
            item.markName
          }}</Option>
        </Select>
        <p></p>
        <li class="bzd">
          <img
            @click="drawMapMark(1)"
            :class="{ active: 1 == current }"
            :src="Icon.dbz"
            alt=""
          />
          <img
            @click="drawMapMark(2)"
            :class="{ active: 2 == current }"
            :src="Icon.xbz"
            alt=""
          />
          <img
            @click="drawMapMark(3)"
            :class="{ active: 3 == current }"
            :src="Icon.mbz"
            alt=""
          />
          <img
            @click="drawMapMark(4)"
            v-if="markIcon.length > 0"
            :class="{ active: 4 == current }"
            :src="Icon.tbz"
            alt=""
          />
          <div v-else></div>
          <span>点标注</span><span>线标注</span><span>面标注</span
          ><span v-show="markIcon.length > 0">图标</span>
        </li>
        <li v-show="current === 4 && drawed">
          <p>图标选择</p>
          <div class="mark-rail" v-show="current == 4">
            <div
              v-for="(m, idx) in markIcon"
              :style="{
                background:
                  markIconType === idx
                    ? 'rgba(120,120,120,0.3)'
                    : 'transparent',
              }"
              :key="idx"
            >
              <img :src="m" @click="changeMarkIcon(idx)" alt="" />
            </div>
          </div>
        </li>
        <p>图层</p>
        <div class="tagdiv">
          <li
            class="tagdel"
            v-for="(item, i) in overlays.spot"
            :class="{ active2: tagId == item.i }"
            :key="i + 'a'"
          >
            <div style="float: left; width: 15%">
              <Icon type="ios-eye" @click="showTag(item.i, $event)" size="20" />
            </div>
            <div class="center">
              <div>{{ item.name }}</div>
              <div>点标注</div>
            </div>
            <div style="float: right" @click="tagDelete(item.i)">
              <Icon type="md-trash" size="20" />
            </div>
          </li>
          <li
            class="tagdel"
            v-for="(item, i) in overlays.line"
            :key="i + 'b'"
            :class="{ active2: tagId == item.i }"
          >
            <div style="float: left; width: 15%">
              <Icon type="ios-eye" @click="showTag(item.i, $event)" size="20" />
            </div>
            <div class="center">
              <div>{{ item.name }}</div>
              <div>线标注</div>
            </div>
            <div style="float: right" @click="tagDelete(item.i)">
              <Icon type="md-trash" size="20" />
            </div>
          </li>
          <li
            class="tagdel"
            v-for="(item, i) in overlays.surface"
            :class="{ active2: tagId == item.i }"
            :key="i + 'c'"
          >
            <div style="float: left; width: 15%">
              <Icon type="ios-eye" @click="showTag(item.i, $event)" size="20" />
            </div>
            <div class="center">
              <div>{{ item.name }}</div>
              <div>面标注</div>
            </div>

            <div style="float: right" @click="tagDelete(item.i)">
              <Icon type="md-trash" size="20" />
            </div>
          </li>
          <li
            class="tagdel"
            v-for="(item, i) in overlays.icon"
            :class="{ active2: tagId == item.i }"
            :key="i + 'd'"
          >
            <div style="float: left; width: 15%">
              <Icon type="ios-eye" @click="showTag(item.i, $event)" size="20" />
            </div>
            <div class="center">
              <div>{{ item.name }}</div>
              <div>图标</div>
            </div>
            <div style="float: right" @click="tagDelete(item.i)">
              <Icon type="md-trash" size="20" />
            </div>
          </li>
        </div>

        <!-- <li>
            <Button type="error"
                    @click="clearMark"
                    :disabled="!drawed">{{
              del == true ? "取消删除" : "删除标注"
            }}</Button>
          </li>
          <li></li>
          <p style="margin-top: 12px">测量</p>
          <li @click="rule('rule')">测距</li>
          <li @click="rule('measureArea')">测面积</li>
          <li>
            <Button type="warning"
                    @click="clearRule">清除测量</Button>
          </li> -->
        <li
          style="
            border: none;
            position: absolute;
            bottom: 30px;
            width: 228px;
            display: flex;
            justify-content: space-around;
          "
        >
          <Button type="info" @click="save" v-show="markID" :disabled="!drawed"
            >保存</Button
          >
          <Button type="success" @click="saveAs" :disabled="!drawed">
            {{ markID == '' ? '保存' : '另存为' }}</Button
          >
          <Button
            type="warning"
            @click="clearOverLays(), (markID = '')"
            :disabled="!drawed"
            >移除</Button
          >
        </li>
      </ul>
    </div>
    <div v-else style="width: 50px"></div>
    <!-- 图标 -->
    <div class="taggingMore" v-if="tagType == 4">
      <li><span>图标</span></li>
      <li>
        <span>经度:{{ nowObj.getPosition().lat.toFixed(3) }}°</span><br />
        <span>纬度:{{ nowObj.getPosition().lng.toFixed(3) }}°</span>
      </li>
      <li>
        <span>标注信息</span>
        <input
          v-model="tagName"
          style="border: 0; padding-left: 5px"
          type="text"
        />
      </li>
      <!-- <li v-if='tagId==""'>
        <span>图标</span>
        <div class="myicon">
          <img v-for="(item,i) in myicon"
               :key="i+'d'"
               @click="cusimg(item.img)"
               :class="{ active: item.img == onImg }"
               :src="item.img"
               alt="">

        </div>
      </li> -->
      <li>
        <Button style="margin-right: 20px" @click="saveDraw" type="primary"
          >确定</Button
        >
        <Button @click="cancelDraw">关闭</Button>
      </li>
    </div>
    <div class="taggingMore" v-if="tagType == 3">
      <li><span>面标注</span></li>
      <!-- <li><span>面积:{{nowObj.getArea()}}平方米</span></li> -->
      <li>
        <span>标注信息</span>
        <input
          v-model="tagName"
          style="border: 0; padding-left: 5px"
          type="text"
        />
      </li>
      <li v-if="tagId == ''">
        <span>样式</span><br />
        <span>颜色:</span>
        <div class="color">
          <colorPicker
            v-on:change="headleChangeColor(1)"
            v-model="mycolor.mcolor"
          />
        </div>
      </li>
      <li>
        <Button style="margin-right: 20px" @click="saveDraw" type="primary"
          >确定</Button
        >
        <Button @click="cancelDraw">关闭</Button>
      </li>
    </div>
    <div class="taggingMore" v-if="tagType == 2">
      <li><span>线标注</span></li>
      <!-- <li><span>总长:{{nowObj.getLength()}}米</span></li> -->
      <li>
        <span>标注信息</span>
        <input
          v-model="tagName"
          style="border: 0; padding-left: 5px"
          type="text"
        />
      </li>
      <li v-if="tagId == ''">
        <span>样式</span><br />
        <span>颜色:</span>
        <div class="color">
          <colorPicker
            v-on:change="headleChangeColor(2)"
            v-model="mycolor.xcolor"
          />
        </div>
      </li>
      <li>
        <Button style="margin-right: 20px" @click="saveDraw" type="primary"
          >确定</Button
        >
        <Button @click="cancelDraw">关闭</Button>
      </li>
    </div>
    <div class="taggingMore" v-if="tagType == 1">
      <li><span>点标注</span></li>
      <li>
        <span
          >经度:{{
            $online
              ? nowObj.getPosition().lat.toFixed(3)
              : nowObj.F.position.lat.toFixed(3)
          }}°</span
        ><br />
        <span
          >纬度:{{
            $online
              ? nowObj.getPosition().lng.toFixed(3)
              : nowObj.F.position.lng.toFixed(3)
          }}°</span
        >
      </li>
      <li>
        <span>标注信息</span>
        <input
          v-model="tagName"
          style="border: 0; padding-left: 5px"
          type="text"
        />
      </li>

      <li>
        <Button style="margin-right: 20px" @click="saveDraw" type="primary"
          >确定</Button
        >
        <Button @click="cancelDraw">关闭</Button>
      </li>
    </div>
    <!-- 标注名称 -->
    <Modal
      v-model="map_mark_name_modal"
      title="地图标注集合名称"
      @on-ok="saveAsOk()"
      @on-cancel="map_mark_name = ''"
    >
      <Input v-model="map_mark_name" maxlength="15" show-word-limit></Input>
    </Modal>
    <!-- 图片视频大图弹窗 -->
    <BigImgModelPath
      v-if="imgModal"
      :imgList="imgList"
      :idx="bigIndex"
      :configHeight="'8rem'"
      @closeBigImgModel="closeImgModal()"
    ></BigImgModelPath>
  </div>
</template>

<script>
let mapId = 0
import _ from 'lodash'

import search from '@/assets/img/Icon/search.png'
import measure from '@/assets/img/Icon/measure.png'
import hierarchy from '@/assets/img/Icon/hierarchy.png'
import zoomIn from '@/assets/img/Icon/jia.png'
import zoomOut from '@/assets/img/Icon/jian.png'
import fix from '@/assets/img/Icon/fix.png'
import mask from '@/assets/img/Icon/mask.png'
import marker from '@/assets/img/Icon/marker.png'
import command from '@/assets/img/Icon/command.png'
import map_mark from '@/assets/img/Icon/map_mark.png'
import avatar from '@/assets/img/statistics/ava1.png'
import sendMarker from '@/assets/img/Marker/Level_2_project.png'
// import PlaneGreen from '@/assets/img/Marker/drone-green.png';
// import PlaneRed from '@/assets/img/Marker/drone-red.png';
// import PlaneYellow from '@/assets/img/Marker/drone-yellow.png';
// import PlaneBlue from '@/assets/img/Marker/drone-blue.png';
// import PlanePurple from '@/assets/img/Marker/drone-purple.png';
import Drone from '@/assets/img/Marker/drone.png'
import Drone_90 from '@/assets/img/Marker/drone_90.png'
// import uav from '@/assets/img/Marker/uav.png';
import cpRPAs from '@/utils/cpRPA.mixin.js'
// import ControlModal from './ControlModal.vue';
import Api from '@/utils/api.js'
import {
  GetNotFlyArea,
  ModelIconList,
  PanoramawebList,
} from '@/utils/javaApi.js'
import navigation from '@/assets/img/Icon/mark_navigation.png'
import doubt from '@/assets/img/Icon/mark_doubt.png'
import set from '@/assets/img/Icon/mark_set.png'
import prohibit from '@/assets/img/Icon/mark_prohibit.png'
import warning from '@/assets/img/Icon/mark_warning.png'
import droneHeight from '@/assets/img/Icon/drone_hight.png'
import droneSpeed from '@/assets/img/Icon/drone_speed.png'
import droneDirection from '@/assets/img/Icon/drone_direction.png'
import dronePosition from '@/assets/img/Icon/drone_position.png'
import origin from '@/assets/img/Icon/origin.png'
import end from '@/assets/img/Icon/end.png'
import track_video from '@/assets/img/Icon/track_video.png'
import tp from '@/assets/img/Icon/target_point.png'
import BigImgModelPath from './bigImgModel_path.vue'
import trackPhotos from '@/assets/img/statistics/track_photos.png'
import dbz from '@/assets/img/statistics/zst_ (1).png'
import xbz from '@/assets/img/statistics/zst_ (2).png'
import mbz from '@/assets/img/statistics/zst_ (3).png'
import tbz from '@/assets/img/statistics/zst_ (4).png'
import videoFullscreen from '@/assets/img/statistics/videoFullscreen.png'
import png3D from '@/assets/img/Marker/3D.png'
import png2D from '@/assets/img/Marker/2D.png'
import parpng from '@/assets/img/Marker/parpng.png'
import line from '@/assets/img/Marker/line.png'
import live from '@/assets/img/Marker/live.png'
export default {
  name: 'commonMap',
  mixins: [cpRPAs],
  props: {
    config: {
      type: Object,
      required: false,
      default: () => {},
    },
    mapFlyingParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    lawFlyingParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    rapidInfo: {
      type: Object,
      default: () => {},
    },
    targetPoint: {
      type: Object,
      default: () => {},
    },
    imgList: {
      type: Array,
      default: () => [],
    },
  },
  components: { BigImgModelPath },
  data() {
    return {
      lineMarkList: [], //储存航线图标数据
      panoramaMarkList: [], //储存全景图标数据
      twoDMarkList: [], //储存2D图标数据
      threeDMarkList: [], //储存3D图标数据
      mapLine: [],
      mapPanorama: [],
      mapTwoDMark: [],
      mapThreeDMark: [],
      userInfo: {},
      mapType: '2d',
      icons: {
        search,
        measure,
        hierarchy,
        zoomIn,
        zoomOut,
        fix,
        mask,
        marker,
        sendMarker,
        avatar,
        // PlaneGreen,
        // PlaneRed,
        // PlaneYellow,
        // PlaneBlue,
        // PlanePurple,
        Drone,
        Drone_90,
        command,
        map_mark,
        droneHeight,
        droneSpeed,
        droneDirection,
        dronePosition,
        origin,
        end,
        track_video,
        trackPhotos,
      },
      localConfig: {
        toolsMovingDistance: 0, // 右边有浮层 工具条定位需要改变的移动距离
        // search: 搜索 measure: 尺子 hierarchy: 图层 zoomIn: 放大 zoomOut: 缩小
        tools: ['hierarchy', 'zoomIn', 'zoomOut'],
      },
      showMore: {
        hierarchy: false,
        search: false,
        measure: false,
        marker: false,
        markerModal: false,
        command: false,
        map_mark: false,
      },
      mapLayer: '卫星',
      mapArea: [],
      mapResource: [],
      searchMsg: '',
      measureType: 'line', // area
      clickPointers: [],
      markList: [],
      lineList: [],
      lineCenterTextList: [],
      airPlaneMarkers: [], // 无人机marker
      sendMsgOptions: {
        // sendMsgBoxStyle: {
        //   top: "0",
        //   lfet: "0",
        // },
        height: 0,
        selectAll: false,
        target_id: [],
        speedRange: {
          min: 0,
          max: 300,
        },
        loading: false,
        longitude: 0,
        latitude: 0,
      },
      sendCommanderOptions: {
        type: 'commander_msg',
        target_id: [],
        loading: false,
        local_data: [
          {
            lat: 0,
            lng: 0,
            height: 120,
          },
        ],
        tag: 'mark_state_navigation', // 目标点类型

        msg_type: 3,
      },
      targetName: '新增目标点', // 标注点名称
      targetRemarks: '', // 标注点备注
      sendMarker: '', // 地图当前目标点集合
      // sMarker: null, //地图当前目标点
      rectangle: null,
      UAVSelectedList: [],
      sendControlMsgOptions: {
        sendControlMsgBoxStyle: {
          top: '0',
          lfet: '0',
        },
        height: 0,
        selectAll: false,
        target_id: [],
        speedRange: {
          min: 0,
          max: 300,
        },
        loading: false,
        longitude: 0,
        latitude: 0,
      },
      // infoWindowCommand:null
      //   ControlModal: false,
      //快拼区域
      editPath: null, //矩形四个角
      rapidDroneStatus: false, //拼图无人机飞行状态
      VideoShou: false, //拼图无人机直播状态
      targetIcon: navigation, // 目标点图标
      navigation,
      doubt,
      set,
      prohibit,
      warning,
      changeType: false,
      showName: 'visible', // 显示名称
      NFzone: [], // 禁飞区合集
      targetPointIcon: tp, // app发送的目标点
      imgModal: false,
      bigIndex: 0,
      maskOpacity: 65,
      mask: {},
      toolshow: true,
      // 地图标注元素
      drawing: false,
      drawed: true,
      tagName: '',
      tagId: '',
      nowObj: '',
      tagType: 0,
      current: 0,
      Icon: {
        dbz: dbz,
        xbz: xbz,
        mbz: mbz,
        tbz: tbz,
      },
      overlays: {
        spot: [], // 点
        line: [], // 线
        surface: [], //  面
        icon: [], //图
      }, // 鼠标绘制物
      markID: '',
      Distance: '',
      delModal: false,
      // mouseTool_map_mark: null,
      isSaveing: false, // 保存函数锁
      mycolor: {
        mcolor: '#5cadff',
        xcolor: '#5cadff',
      },
      map_mark_name_modal: false,
      map_mark_name: '',
      mapMarkList: [],
      overlayGroup: {},

      // 地图图标标注切换
      markIcon: [], // 标记图标集合
      markIconType: 0,
      onImg: 'https://www.jointflight.cn/fireman.png',
    }
  },
  computed: {
    // 每个地图的id 有可能一个页面多个map
    id: {
      get() {
        return 'commonMap' + mapId++
      },
    },
    // 右下角按钮的位置
    dynamicStyle() {
      return (this.config || {}).toolsMovingDistance + 0.2 || 0.2
      //   return 3;
    },
    // 目标点面板的位置Target point
    // 是否建线任务
    isLine() {
      return this.config.taskType !== 'area'
    },
  },
  watch: {
    mapLayer(val) {
      //卫星平面切换
      if (val == '平面') {
        this.map.remove(this.satelliteLayer)
        // this.map.add(this.roadNetLayer);
      } else if (val == '卫星') {
        this.map.add(this.satelliteLayer)
      }
    },
    mapArea(val) {
      //路网,禁飞区显示
      // 路网
      if (val.indexOf('路网') !== -1) {
        this.map.add(this.roadNetLayer)
      } else {
        this.map.remove(this.roadNetLayer)
      }
      if (val.indexOf('虚拟护栏') !== -1) {
        if (this.NFzone.length > 0) {
          this.map.add(this.NFzone)
          this.NFzone.forEach((element) => {
            element.show()
          })
        } else {
          this.initNFzone()
        }
      } else {
        this.NFzone.forEach((element) => {
          element.hide()
        })
      }
      //显示默认标注
      if (val.indexOf('地名') !== -1) {
        let features = ['bg', 'road', 'building', 'point']
        this.map.setFeatures(features)
      } else {
        let features = ['bg', 'road', 'building']
        this.map.setFeatures(features)
      }
    },
    mapLine(val) {
      if (val.indexOf('航线') !== -1) {
        this.$store.commit('SET_AirLineState', true)
        this.LDResource(1)
      } else {
        this.$store.commit('SET_AirLineState', false)
        this.delMarkers('airLine')
      }
    },
    mapPanorama(val) {
      if (val.indexOf('全景照片') !== -1) {
        this.$store.commit('SET_PanoramaState', true)
        this.LDResource(2)
      } else {
        this.$store.commit('SET_PanoramaState', false)
        this.delMarkers('panorama')
      }
    },
    mapTwoDMark(val) {
      if (val.indexOf('二维正射') !== -1) {
        this.$store.commit('SET_TwoMarkState', true)
        this.LDResource(3)
      } else {
        this.$store.commit('SET_TwoMarkState', false)
        this.delMarkers('twoDMark')
      }
    },
    mapThreeDMark(val) {
      if (val.indexOf('三维模型') !== -1) {
        this.$store.commit('SET_ThreeMarkState', true)
        this.LDResource(4)
      } else {
        this.$store.commit('SET_ThreeMarkState', false)
        this.delMarkers('threeDMark')
      }
    },
    // 无人机轨迹回放处理 在父页面改变了curIndex导致触发了这里的回调 进行移动
    mapFlyingParams: {
      deep: true,
      handler: function (val) {
        if (val.playingStatus) {
          // 开始或者继续
          if (val.curIndex < val.allPlayingCounts) {
            this.renderPolylinePath()
          }
        } else {
          if (val.pathArr && val.pathArr.length) {
            // 初始化 数据已经准备完毕
            if (!this.drawMarker) {
              this.initPolylinePath()
            }
          }
          // 暂停或者停止
          if (this.drawMarker) {
            if (val.curIndex === val.allPlayingCounts) {
              this.drawMarker.stopMove()
            } else {
              this.drawMarker.pauseMove()
            }
          }
        }
      },
    },
    // 执法记录仪轨迹回放处理
    lawFlyingParams: {
      deep: true,
      handler: function (val) {
        if (val) {
          console.log('执法记录仪轨迹回放处理2')
          this.initLawPolylinePath(val)
        } else {
          console.log('执法记录仪轨迹回放数据错误')
        }
      },
    },
    // 通知配置
    config: {
      deep: true,
      handler: function (val) {
        // console.log("登录了的人信息",val);
        if (Object.keys(val).indexOf('onEventHandler') !== -1) {
          // 需要绑定额外的点击事件的时候才执行
          this.toggleMarkerClickOrDragEvent(val.onEventHandler || false)
        }
        if (Object.keys(val).indexOf('markLength') !== -1) {
          // setting页面更新了marker的数量 map需要同步页面
          this.updateMarkCounts()
        }
        // 编辑已有项目需要重新绘制下地图
        if (val.reDraw) {
          if (val.taskType === '3d-line') {
            this.mapType = '3d'
            this.initMap()
          }
          console.log('编辑页面，重绘地图……')
          this.drawLineOrArea(val.points)
          val.reDraw = false
        }
        // socket数据更新 地图同时更新
        if (val.updateBySocket) {
          // console.log(val)
          this.drawAirPlanes()
          val.updateBySocket = false
        }

        // 单个节点的高度更新
        if (val.UpdateBysinglePointList) {
          this.syncSinglePointList()
          val.UpdateBysinglePointList = false
        }

        // 自动规划的航线更新
        if (val.UpdateByNeedReRenderAutoLine) {
          // todo
          this.renderRPAPolylineHandler()
          val.UpdateByNeedReRenderAutoLine = false
        }
        //快速拼图任务
        if (val.rapidStatus) {
          console.log('快速拼图任务')
          if (this.editPath !== null) {
            // this.rapidMouseTool=this.mouseTool.rectangle({
            //   strokeColor:'red',
            //   strokeOpacity:0.5,
            //   strokeWeight: 6,
            //   fillColor:'blue',
            //   fillOpacity:0.5,
            //   // strokeStyle还支持 solid
            //   strokeStyle: 'solid',
            //   // strokeDasharray: [30,10],
            // });
            this.rapidRPAPolylineHandler(
              JSON.parse(sessionStorage.getItem('editPath'))
            )
          }
        } else {
        }
      },
    },
    //快速拼图的实时Socket信息
    rapidInfo: {
      deep: true,
      handler: function (val) {
        // console.log('快速拼图的实时Socket信息', val);
        if (val == null) {
          return
        }

        if (val.track_tag == 1 && !this.rapidDroneStatus) {
          //快速拼图正在飞行
          this.rapidDroneStatus = true
          this.rapidMarker = new AMap.Marker({
            position: [val.DroneLongitude, val.DroneLatitude],
            icon: new AMap.Icon({
              size: new AMap.Size(34, 36), //图标大小
              imageSize: new AMap.Size(34, 36),
              image: this.icons.Drone,
            }),
            offset: new AMap.Pixel(-17, -18),
            autoRotation: true,
          })
          this.map.add(this.rapidMarker)
          console.log('注册飞机结束', this.rapidMarker)
        }
        if (val.track_tag == 1) {
          console.log('更新飞机位置', this.rapidMarker)
          // this.rapidMarker.setPosition(new AMap.LngLat(
          //         val.DroneLongitude,
          //         val.DroneLatitude
          // ));
          this.rapidMarker.moveTo(
            new AMap.LngLat(val.DroneLongitude, val.DroneLatitude),
            this.config.rapidData.speed * 3.6
          )
          this.rapidMarker.setAngle(Number(val.DroneYaw))
        }
        if (val.track_tag == 0 && this.rapidDroneStatus) {
          this.rapidDroneStatus = false
        }

        if (val.IsPublish == 1 && !this.VideoShou) {
          this.VideoShou = true
          this.$_bus.$emit('RapidVideo', true)
        } else if (val.IsPublish == 0) {
          this.VideoShou = false
          this.$_bus.$emit('RapidVideo', false)
        }
      },
    },
    // 目标点配置
    targetPoint: {
      deep: true,
      handler: function (val) {
        console.log('watchtarget', val)
        let target = new AMap.Marker({
          position: new AMap.LngLat(val.longitude, val.latitude),
          icon: this.targetPointIcon, // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-15, -15),
        })
        console.log('target', target)
        this.map.add(target)
      },
    },
    // 回放图片
    imgList(val) {
      val.forEach((ele, index) => {
        let img = new AMap.Marker({
          position: new AMap.LngLat(ele.lng, ele.lat),
          icon: new AMap.Icon({
            size: new AMap.Size(20, 20), // 图标尺寸
            image: this.icons.trackPhotos, // Icon的图像
            imageSize: new AMap.Size(20, 20), // 根据所设置的大小拉伸或压缩图片
          }),
          offset: new AMap.Pixel(-10, -10),
          zIndex: 150,
        })
        img.on('click', () => {
          this.bigImg(index)
        })
        this.map.add(img)
      })
    },
  },
  created() {
    this.localConfig.toolsMovingDistance =
      (this.config || {}).toolsMovingDistance || 0
    this.localConfig.tools = (this.config || {}).tools || [
      'hierarchy',
      'zoomIn',
      'zoomOut',
    ]
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'))
    if (this.config.immediatelyNF) {
      if (this.NFzone.length > 0) {
        //   this.map.add(this.NFzone);
        this.NFzone.forEach((element) => {
          element.show()
        })
      } else {
        this.initNFzone()
      }
    }
    let _this = this
    this.initMap()
    if (!!this.mouseTool) {
      //监听地图画矢量图形结束
      this.mouseTool.on('draw', function (e) {
        //群体飞控
        // if (_this.showMore.command) {
        //   _this.ControlAreaEnd(e);
        // }
        //快速拼图任务
        if (_this.config.rapidStatus) {
          _this.rapidAreaEnd(e)
        }
      })
    }
    this.$_bus.$off('clearMap')
    this.$_bus.$on('clearMap', function () {
      _this.map.clearMap()
      _this.editPath = null
      _this.rapidDroneStatus = false //拼图无人机飞行状态
      _this.VideoShou = false
    })
    this.$_bus.$off('mapControlRapid')
    this.$_bus.$on('mapControlRapid', function (val) {
      if (val.isMapOperateLocked) {
        _this.rapidMouseTool = _this.mouseTool.rectangle({
          strokeColor: 'red',
          strokeOpacity: 0.5,
          strokeWeight: 6,
          fillColor: 'blue',
          fillOpacity: 0.5,
          // strokeStyle还支持 solid
          strokeStyle: 'solid',
          // strokeDasharray: [30,10]
        })
      } else {
        _this.mouseTool.close()
      }
    })
    this.initMarkersState()


    if(this.$JL){
      if(this.$route.query.jiLiName!==undefined&&this.$route.query.jiLiName!=='undefined'){
        //吉林名称
        let jiLiName=this.$route.query.jiLiName
        this.jilinSearch(jiLiName)
        let features = ['bg', 'road', 'building', 'point']
        this.map.setFeatures(features)
      }
    }
  },

  beforeDestroy() {
    this.getMapZoom()
  },
  methods: {
    // 抛出发送消息的内容
    sendSocketMsg(data) {
      this.$emit('sendSocketMsg', data)
    },
    initMap() {
      // 引入瓦片图-平面

      if (this.$online) {
        this.layer = new AMap.TileLayer()
        // 卫星图
        this.satelliteLayer = new AMap.TileLayer.Satellite()
        // 路网图
        this.roadNetLayer = new AMap.TileLayer.RoadNet()
      } else {
        this.layer = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png'
          },
          zIndex: 10,
        })
        this.satelliteLayer = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeSatelliteMap() + z + '/' + x + '/' + y + '.png'
          },
          zIndex: 10,
        })
      }
      // 创建地图
      if (this.mapType === '2d') {
        let pitchMapCenter = this.$store.state.pitchMapCenter
        let pitchMapZoom = this.$store.state.mapZoom
        //动画插件
        // AMap.plugin('AMap.MoveAnimation', () => {
        this.map = new AMap.Map(this.id, {
          // viewMode: '2D', //是否为3D地图模式
          resizeEnable: true, //是否监控地图容器尺寸变化
          // expandZoomRange:true,
          // zooms:[3,20],
          zoom: pitchMapZoom === null ? 14 : pitchMapZoom, //初始化地图层级
          center: pitchMapCenter.length > 0 ? pitchMapCenter : this.$Center, //初始化地图中心点
          // crs: "EPSG4326", // 声明坐标系
          // 获取瓦片文件
          layers: [this.layer],

          // zoom: 20, //初始化地图级别
          // center: [105.602725, 37.076636], //初始化地图中心点位置
        })
        // })

        this.map.add(this.satelliteLayer)
        // this.map.add(xyzTileLayer);
        // 修改主题样式
        // if (this.$online) {
        //   this.map.setMapStyle('amap://styles/whitesmoke');
        // }
      } else {
        this.map = new AMap.Map(this.id, {
          pitch: 75, // 地图俯仰角度，有效范围 0 度- 83 度
          viewMode: '3D', // 地图模式
          resizeEnable: true, //是否监控地图容器尺寸变化
          zoom: 11, //初始化地图层级
          center: this.$Center,
          zooms: [3, 20],
          // center: [112.97935279, 28.21347823], //初始化地图中心点
        })
        // 修改主题样式
        // if (this.$online) {
        //   this.map.setMapStyle('amap://styles/whitesmoke');
        // }
      }

      // this.backCenter();
      //默认不显示标注
      var features = ['bg', 'road', 'building']
      this.map.setFeatures(features)
      // 无路网图、平面图，暂时屏蔽
      //   if (this.localConfig.tools.includes('hierarchy')) {
      //     //卫星图
      //     this.satelliteLayer = new AMap.TileLayer.Satellite();
      //     // this.satelliteLayer = new AMap.TileLayer({
      //     //   getTileUrl: function (x, y, z) {
      //     //     return Api.GaoDeSatelliteMap() + z + '/' + x + '/' + y + '.png';
      //     //   },
      //     //   zIndex: 10,
      //     // });
      //   }
      if (this.localConfig.tools.includes('measure')) {
        // 注册高德测量
        this.mouseTool = new AMap.MouseTool(this.map)
      }

      // 无人机航线自动规划
      this.initRPALine(this.map)

      // 初始化禁飞区
      //   this.initNFzone();
      let outer = [
        new AMap.LngLat(-360, 90, true),
        new AMap.LngLat(-360, -90, true),
        new AMap.LngLat(360, -90, true),
        new AMap.LngLat(360, 90, true),
      ]

      this.mask = new AMap.Polygon({
        path: outer,
        strokeColor: '#000',
        strokeWeight: 1,
        fillColor: '#000',
        fillOpacity: 1 - this.maskOpacity / 100,
        bubble: true,
      })
      this.map.add(this.mask)

      // 地图标注覆盖物群组
      this.overlayGroup = new AMap.OverlayGroup()
      this.map.add(this.overlayGroup)
      // this.LDResource()
    },
    // 初始化轨迹
    initPolylinePath() {
      let config = this.mapFlyingParams
      if (!this.drawMarker) {
        this.drawMarker = new AMap.Marker({
          map: this.map,
          position: [config.pathArr[0][0], config.pathArr[0][1]],
          icon: this.icons.Drone_90,
          offset: new AMap.Pixel(-18, -17),
          autoRotation: true,
        })
        this.map.setFitView(this.drawMarker)
      }

      // 绘制轨迹
      if (!this.drawPolyline) {
        let fullPath = _.cloneDeep(config.pathArr)
        this.drawPolyline = new AMap.Polyline({
          map: this.map,
          path: fullPath,
          showDir: true,
          strokeColor: '#0AEFD5', //线颜色
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"     //线样式
          lineJoin: 'round',
          lineCap: 'round',
        })
        this.map.setFitView(this.drawPolyline)
      }

      if (!this.passedPolyline) {
        this.passedPolyline = new AMap.Polyline({
          map: this.map,
          // path: lineArr,
          strokeColor: '#AF5', //线颜色
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
          lineJoin: 'round',
          lineCap: 'round',
        })
        this.map.setFitView(this.passedPolyline)
      }
    },
    //加载执法记录仪轨迹记录
    initLawPolylinePath(val) {
      console.log('进入加载执法记录仪轨迹记录方法')
      let path = eval(val.local_data).map((ele) => {
        return [parseFloat(ele.lng), parseFloat(ele.lat)]
      })
      console.log(path)
      this.drawPolyline = new AMap.Polyline({
        map: this.map,
        path: path,
        showDir: true,
        strokeColor: '#28F', //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, //线宽
        // strokeStyle: "solid"     //线样式
      })
      this.map.setFitView(this.drawPolyline)
      // 起点终点
      var originMarker = new AMap.Marker({
        map: this.map,
        position: path[0],
        icon: new AMap.Icon({
          size: new AMap.Size(20, 20), // 图标尺寸
          image: origin, // Icon的图像
          imageSize: new AMap.Size(20, 20), // 根据所设置的大小拉伸或压缩图片
        }),
        offset: new AMap.Pixel(-10, -10),
        autoRotation: true,
      })
      var endMarker = new AMap.Marker({
        map: this.map,
        position: path[path.length - 1],
        icon: new AMap.Icon({
          size: new AMap.Size(20, 20), // 图标尺寸
          image: end, // Icon的图像
          imageSize: new AMap.Size(20, 20), // 根据所设置的大小拉伸或压缩图片
        }),
        offset: new AMap.Pixel(-10, -10),
        autoRotation: true,
      })

      if (val.media) {
        val.media.forEach((item, ind) => {
          if (item.media_type == 2) {
            new AMap.Marker({
              map: this.map,
              position: [item.localtion.lng, item.localtion.lat],
              icon: new AMap.Icon({
                size: new AMap.Size(30, 30), // 图标尺寸
                image: track_video, // Icon的图像
                imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -15),
              autoRotation: true,
            })
          } else if (item.media_type == 1) {
            new AMap.Marker({
              map: this.map,
              position: [item.localtion.lng, item.localtion.lat],
              icon: new AMap.Icon({
                size: new AMap.Size(30, 30), // 图标尺寸
                image: item.path, // Icon的图像
                imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -15),
              autoRotation: true,
            })
          }
        })
      }
    },
    // 更新轨迹绘制的线条
    renderPolylinePath() {
      let config = this.mapFlyingParams
      let _this = this

      // if (!this.drawMarker.onMoving) {
      //   this.drawMarker.on("moving", function(e) {
      //     if (!_this.passedPath) {
      //       _this.passedPath = [];
      //     }
      //     _this.passedPath.concat(...(e.passedPath))
      //     _this.passedPolyline.setPath(this.passedPath);
      //   });
      //   this.drawMarker.onMoving = true
      // }

      // 先停 再以新速度开始
      let movePath = _.cloneDeep(
        config.pathArr
          .slice(config.curIndex, config.curIndex + 2)
          // new AMap.LngLat(ele[0], ele[1])
          .map((ele) => new AMap.LngLat(ele[0], ele[1]))
      )
      if (movePath.length < 2) {
        return
      }

      this.drawMarker && this.drawMarker.pauseMove()
      // 执行moveTo的时候会一直报个错误 原因是 moveTo执行中this的指向改变了 导致报错 虽然不会导致线程挂掉 实在是没找到原因 高德地图new Maker构造的过程据说是异步的 导致的这个问题 实在是没办法了目前 就先这样吧
      try {
        this.drawMarker && this.drawMarker.moveAlong(movePath,config.speed)
        // this.drawMarker &&
        //   this.drawMarker.moveAlong(movePath, {
        //     duration: 200, //可根据实际采集时间间隔设置
        //     // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
        //     autoRotation: true,
        //   })
      } catch (e) {
        // 高德的异步api错误无法被捕获 没得办法
        console.log(e)
      }

      // +1 落后正常飞行一步
      let passedPath = _.cloneDeep(
        config.pathArr
          .slice(0, config.curIndex + 1)
          .map((ele) => new AMap.LngLat(ele[0], ele[1]))
      )
      if (passedPath.length) {
        _this.passedPolyline.setPath(passedPath)
      }
    },
    // 绘制无人机
    drawAirPlanes() {
      // 用户marker
      // console.log('绘制无人机');
      this.clearAllNotOnLinePlanes()
      this.config.onLineList.forEach((ele) => {
        let markers = this.airPlaneMarkers.filter(
          (marker) => marker.uid.toString() === ele.user_id.toString()
        )
        if (!markers.length) {
          // console.log('新建用户');
          this.generateUserMarker(ele)
        } else {
          // console.log('更新数据',ele, markers);
          this.updateUserMarker(ele, markers)
        }
      })
    },
    // 清除不在线的用户和无人机
    clearAllNotOnLinePlanes() {
      this.airPlaneMarkers.forEach((ele, ind) => {
        // console.log("检验",ele.type);
        if (
          this.config.onLineList.findIndex(
            (item) => item.user_id === ele.uid
          ) === -1
        ) {
          this.map.remove(ele)
          // ele = null;
          this.airPlaneMarkers[ind] = null
          // this.airPlaneMarkers.splice(ind, 1);
        }
      })
      this.airPlaneMarkers = this.airPlaneMarkers.filter((e) => e !== null)

      // console.log("删除不在线",this.airPlaneMarkers,this.config.onLineList);
    },
    // 更新marker的一些dom属性
    updateMarkerDoms(marker, ele) {
      let newContent = this.getPlaneContent(ele)
      marker.setContent(newContent)
    },
    //更新用户marker的dom属性
    updateUserDoms(marker, ele) {
      let newUserContent = this.getUserContent(ele)
      marker.setContent(newUserContent)
    },
    // 更新用户 无人机 链接线的数据
    updateUserMarker(ele, markers) {
      if (markers.length) {
        markers.forEach((marker) => {
          if (marker.type === 'user') {
            if (
              ele.UserLongitude &&
              ele.UserLatitude &&
              ele.UserLatitude !== 'NaN' &&
              ele.UserLongitude !== 'NaN' &&
              ele.UserLatitude !== '0' &&
              ele.UserLongitude !== '0'
            ) {
              //更改最新坐标
              marker.setPosition(
                new AMap.LngLat(
                  parseFloat(ele.UserLongitude) || 0,
                  parseFloat(ele.UserLatitude) || 0
                )
              )
              //
              this.updateUserDoms(marker, ele)
              if (!marker.isAdded) {
                this.map.add(marker)
                marker.isAdded = true
              }
            }
          }
          if (marker.type === 'plane') {
            let L = ele.positionList.length
            if (L > 0) {
              let lastNewAirPlanePosition = new AMap.LngLat(
                parseFloat(ele.positionList[L - 1][0]) || 0,
                parseFloat(ele.positionList[L - 1][1]) || 0
              )
              marker.setPosition(lastNewAirPlanePosition)
              // marker.setAngle(Number(ele.DroneYaw));
              this.updateMarkerDoms(marker, ele)

              if (!marker.isAdded) {
                this.map.add(marker)
                marker.isAdded = true
                this.connectUserAndPlane(ele)
              }
            }
          }
          if (marker.type === 'line') {
            ele.positionList.splice(0, 1, [
              parseFloat(ele.UserLongitude),
              parseFloat(ele.UserLatitude),
            ])
            let lineArrPath = _.cloneDeep(ele.positionList)
            marker.setPath(lineArrPath)
          }
        })
      }
      let a = document.querySelectorAll('.amap-marker-content')
      if (a.length > 0) {
        a.forEach((element) => {
          element.style.whiteSpace = 'normal'
        })
      }
    },
    // 获取无人机颜色种类
    getPlaneIcon(color) {
      let icon
      //   switch (color) {
      //     case '#FF685B':
      //       icon = this.icons.PlaneRed;
      //       break;
      //     case '#FF9500':
      //       icon = this.icons.PlaneYellow;
      //       break;
      //     case '#4C98FF':
      //       icon = this.icons.PlaneBlue;
      //       break;
      //     case '#61D76B':
      //       icon = this.icons.PlaneGreen;
      //       break;
      //     case '#C179E5':
      //       icon = this.icons.PlanePurple;
      //       break;
      //     default:
      //       icon = this.icons.Drone;
      //   }
      icon = this.icons.Drone
      return icon
    },
    // 获取无人机dom
    getPlaneContent(ele) {
      let content = `
      <div class="marker-wrapper flex drone-icon" id="plane_${ele.user_id}">
        <div style="position:absolute;top:-30px;background:rgba(0,0,0,0.6);color:#FFF;white-space:pre;visibility:${
          this.showName
        }" class="if_name_drone flex"><img style="display:${
        ele.IsPublish == 1 ? 'block' : 'none'
      };margin-right:5px" src="${live}" />${ele.DRONEMODEL}</div>
        <div class="plane-power-wrapper" style="position:absolute;top:-10px;">
          <div class="plane-power" style="width: ${parseFloat(
            ele.power || 0
          )}%;background-color: ${ele.comment || '#4de639'};">
          </div>
        </div>
        <div class="plane-avatar flex">
          <img style="transform: rotate(${
            ele.DroneYaw || 0
          }deg)" src="${this.getPlaneIcon(ele.comment)}" />
        </div>
        <div class="drone-info">
          <div class="drone-info-1">
          <p><img src="${ele.head_icon}" />${ele.name}</p><span style="color:${
        ele.IsPublish == 0 ? '#f00' : '#007acc'
      }">${ele.IsPublish == 0 ? '未直播' : '正在直播'}</span>
          </div>
          <div class="drone-info-2">
          <img src="${this.icons.droneHeight}" />
          <span>${parseFloat(ele.DroneAltitude).toFixed(1)}m</span>
          <img src="${this.icons.droneSpeed}" style="margin-left:18px;"/>
          <span>${parseFloat(ele.horizontal_velocity).toFixed(1)}m/s</span>
          <img src="${this.icons.droneDirection}" style="margin-left:18px;"/>
          <span>${parseInt(ele.DroneYaw)}'</span>
          </div>
          <div class="drone-info-3">
          <img src="${this.icons.dronePosition}" />
          <span>${parseFloat(ele.PointLatitude).toFixed(6)},${parseFloat(
        ele.PointLongitude
      ).toFixed(6)}</span>
          </div>
          </div>
          </div>
          `
      return content
    },
    //用户marker的dom
    getUserContent(ele) {
      let user_content = `
      <div class="marker-wrapper flex">
        <div class="user-name flex if_name">  <img style="display:${
          ele.IsPublish == 1 ? 'block' : 'none'
        }" src="${live}" /> ${ele.name}</div>
        <div class="user-avatar flex" style="border-color: ${ele.comment}">
          <img src="${ele.head_icon_path || ele.head_icon}" />
        </div>
      </div>
      `
      return user_content
    },
    // 生成无人机和用户dom
    generateUserMarker(ele) {
      // 用户
      let user_content = this.getUserContent(ele)
      let user_marker = new AMap.Marker({
        content: user_content, // 自定义点标记覆盖物内容
        position: [
          parseFloat(ele.UserLongitude) || 0,
          parseFloat(ele.UserLatitude) || 0,
        ], // 基点位置
        offset: new AMap.Pixel(-65, -47), // 8/16确认使用offset
        // offset: new AMap.Pixel(-51, -29), // 22/1/14
        // anchor: 'center', // 设置锚点方位
        draggable: false,
        // extData: { dateID: ele },
      })
      user_marker.uid = ele.user_id
      user_marker.data = ele
      user_marker.type = 'user'
      //触发点击事件向父组件触发getUser并传递用户信息
      user_marker.on('click', () => {
        this.$emit('getUser', ele)
      })
      this.airPlaneMarkers.push(user_marker)
      if (ele.UserLongitude && ele.UserLatitude) {
        this.map.add(user_marker)
        user_marker.isAdded = true
      }
      //点击用户图标弹出对话框
      // user_marker.on('click', (e) => {
      //   if (e.target.data.IsPublish == 1) {
      //     this.$_bus.$emit('showChatHandler2', e.target.data);
      //   }
      // });

      // 无人机
      let plane_content = this.getPlaneContent(ele)
      // let plane_icon = this.getPlaneIcon();
      let L = ele.positionList.length
      let lastPosition =
        L > 0
          ? [
              parseFloat(ele.positionList[L - 1][0]) || 0,
              parseFloat(ele.positionList[L - 1][1]) || 0,
            ]
          : [0, 0]
      let plane_marker = new AMap.Marker({
        // icon: plane_icon,
        content: plane_content, // 自定义点标记覆盖物内容
        position: lastPosition, // 基点位置
        offset: new AMap.Pixel(-16, -16),
        draggable: false,
      })
      plane_marker.uid = ele.user_id
      plane_marker.type = 'plane'
      this.airPlaneMarkers.push(plane_marker)
      if (L > 0) {
        // 连上了无人机有数据才显示
        this.map.add(plane_marker)
        plane_marker.isAdded = true
      }

      // 连线
      if (!L) return
      this.connectUserAndPlane(ele)
    },
    // 用户和无人机连接线
    connectUserAndPlane(ele) {
      let linePathArr = ele.positionList.unshift([
        parseFloat(ele.UserLongitude) || 0,
        parseFloat(ele.UserLatitude) || 0,
      ])
      let polyline = new AMap.Polyline({
        map: this.map,
        path: linePathArr,
        showDir: false,
        strokeColor: ele.comment, //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 1, //线宽
        // strokeStyle: "solid"  //线样式
      })
      polyline.uid = ele.user_id
      polyline.type = 'line'
      this.airPlaneMarkers.push(polyline)
    },
    // marker的数量更新了(一般是setting那边删减了) 地图同步更新
    updateMarkCounts() {
      if (!this.config.onEventHandler) return
      if (this.clickPointers.length !== this.config.markLength) {
        // 删除mark
        for (
          let i = 0, L = this.clickPointers.length - this.config.markLength;
          i < L;
          ++i
        ) {
          let markL = this.clickPointers.length
          this.map.remove(this.markList[markL - 1])
          this.clickPointers.splice(markL - 1, 1)
          this.markList.splice(markL - 1, 1)
          let lineTextLength = this.lineCenterTextList.length
          if (lineTextLength) {
            this.map.remove(this.lineCenterTextList[lineTextLength - 1])
            this.lineCenterTextList.splice(lineTextLength - 1, 1)
            this.map.remove(this.lineList[lineTextLength - 1])
            this.lineList.splice(lineTextLength - 1, 1)
          }
        }
        if (this.isLine) {
          if (this.activeMarker > this.clickPointers.length - 1) {
            let newActiveMarker = this.clickPointers.length
              ? this.clickPointers.length
              : -1
            this.$emit('updateActiveMarker', {
              activeMarker: newActiveMarker,
            })
            this.activeMarker = newActiveMarker - 1
            setTimeout(() => {
              this.toggleMarkerCss()
            }, 0)
          }
          this.createLine()
        } else {
          this.createLine(true)
          this.createPolygon()
        }
        this.throwMapData()
        console.log(`当前mark数量为${this.config.markLength}个`)
      }
    },
    // 自动规划航线
    renderRPAPolylineHandler() {
      if (this.clickPointers.length < 2) return
      let path = this.clickPointers.map((item) => {
        return new AMap.LngLat(item[0], item[1])
      })
      let setting = this.config.settingModalData
      //   let fov = (setting.defaultFov || 84) / 2;
      //   let rad = (fov * Math.PI) / 180;
      //   let hypotenuseLength = setting.height * Math.tan(rad) * 2;
      // let photeDis =
      //     hypotenuseLength * (3 / 5) * (1 - setting.courseOverlapRate / 100);
      // let planeSpaceDis =
      //     hypotenuseLength * (4 / 5) * (1 - setting.sideOverlapRate / 100);
      let photeDis = this.calculateonOverlapRateNum(setting)
      let planeSpaceDis = this.calculateBetweenOverlapRateNum(setting)
      let rotate = -90 + setting.courseAngle
      this.renderRPAPolyline(path, {
        stepRotate: rotate,
        spaceInp: planeSpaceDis,
      })
      // 更新照片数据
      let autoLinePoints = this.RPA_polyline.latlngs.map((item) => [
        item.lng,
        item.lat,
      ])
      let autoLineLength = Math.round(
        AMap.GeometryUtil.distanceOfLine(autoLinePoints)
      )
      // 传递自动规划航点数量
      this.$emit('getAutoLinePointsNum', autoLinePoints.length)
      let allPhotoCount = parseInt(autoLineLength / (photeDis || 1))
      this.config.settingModalData.photoGraphTimes = allPhotoCount
    },
    // 创建多边形
    createPolygon() {
      console.log('创建多边形===')
      let path = this.clickPointers.map((item) => {
        return new AMap.LngLat(item[0], item[1])
      })
      if (this.polygon) {
        this.polygon.setPath(path)
        // this.map.remove(this.polygon);
        if (path.length > 1) {
          this.renderRPAPolylineHandler()
        } else {
          this.initRPADraw()
        }
      } else {
        // 创建区域实例
        this.polygon = new AMap.Polygon({
          path: path,
          map: this.map,
          strokeColor: '#E90000',
          strokeWeight: 1,
          strokeOpacity: 1,
          fillOpacity: 0.16,
          fillColor: '#E90000',
          zIndex: 50,
        })
        this.initRPADraw()
      }
      //  绘制无人机航线

      // return this.polygon;
    },
    // 绘制线或者图
    createLineOrArea() {
      if (this.isLine) {
        this.createLine()
        // let polyline = this.createLine();
        // this.map.add(polyline);
      } else {
        this.createLine(true)
        this.createPolygon()
        // let polygon = this.createPolygon();
        // this.map.add(polygon);
        // 缩放地图到合适的视野级别
        // this.map.setFitView([polygon])
      }
    },
    // 根据pointsList的数量来绘制
    drawLineOrArea(pointsList) {
      for (let i = 0, L = pointsList.length; i < L; i++) {
        let lng = pointsList[i][0]
        let lat = pointsList[i][1]
        let marker = this.createMarker({
          lng,
          lat,
          index: i,
          text: this.isLine ? this.clickPointers.length + 1 || '' : '',
        })
        this.markList.push(marker)
        this.clickPointers.push([lng, lat, marker._amap_id])
        this.createLineOrArea()
        this.map.add(marker)
        this.throwMapData()
      }
    },

    // 指点飞行的时候设置地图不可操作
    toggleeMapDrag(drag) {
      let mapOpts = {
        dragEnable: drag, // 地图是否可通过鼠标拖拽平移，默认为true
        keyboardEnable: drag, //地图是否可通过键盘控制，默认为true
        doubleClickZoom: drag, // 地图是否可通过双击鼠标放大地图，默认为true
        zoomEnable: drag, //地图是否可缩放，默认值为true
      }
      this.map.setStatus(mapOpts)
    },
    // 提交指挥官目标点
    submitSendCommander() {
      // 发送给全部在线飞手;
      if (this.config.onLineList.length == 0) {
        this.$Notice.warning({
          title: '暂无飞手在线!',
        })
        return
      } else if (this.sendCommanderOptions.local_data[0].lat == 0) {
        this.$Notice.warning({
          title: '请先点选目标点',
        })
        return
      } else {
        this.sendCommanderOptions.loading = true
        this.sendCommanderOptions.target_id = this.config.onLineList
          .map((ele) => ele.user_id)
          .toString()
        console.log('提交指挥官目标点', this.sendCommanderOptions.target_id)
        // post发送
        // this.$emit(
        //   'sendCommander',
        //   JSON.parse(JSON.stringify(this.sendCommanderOptions))
        // );

        // ws发送
        this.$emit(
          'sendSocketMsg',
          JSON.parse(JSON.stringify(this.sendCommanderOptions))
        )

        // 设置标记点名称;
        this.sendMarker.setLabel({
          offset: new AMap.Pixel(0, -2),
          content: `<div class="if_name">${this.targetName}</div>`, //设置文本标注内容
          direction: 'top',
        })
        this.changeName(this.showName)
        this.sendMarker = ''
      }
      // 这段会导致sendCommanderOptions参数被重置
      this.showMore.markerModal = false
      this.showMore.marker = false
      this.resetSendCommanderOptions()
      this.toggleMarkerClickOrDragEvent(false)
    },
    // 重置参数
    resetSendMsgOptions() {
      this.sendMsgOptions.height = 0
      this.sendMsgOptions.selectAll = false
      this.sendMsgOptions.loading = false
      this.config.onLineList.forEach((ele) => {
        ele.selected = false
      })
      this.sendMsgOptions.target_id = []
    },
    resetSendCommanderOptions() {
      this.sendCommanderOptions.loading = false
      this.sendCommanderOptions.target_id = []
      this.sendCommanderOptions.local_data[0].lng = 0
      this.sendCommanderOptions.local_data[0].lat = 0
      this.targetIcon = navigation
    },
    // 选择指点飞行人员
    toggleSender(type, target) {
      if (type === 'one') {
        this.config.onLineList[target].selected =
          !this.config.onLineList[target].selected
      } else {
        this.config.onLineList.map((ele) => {
          ele.selected = !!type
          return ele
        })
      }
      // console.log(this.config.onLineList)
    },
    // 取消指点飞行
    cancelSendMsg(deleteMarker = true) {
      this.toggleeMapDrag(true)
      this.showMore.markerModal = false
      this.showMore.marker = false
      if (deleteMarker) {
        this.map.remove(this.sendMarker)
      }
      this.sendMarker = null
      this.resetSendMsgOptions()
      this.toggleMarkerClickOrDragEvent(false)
    },
    // 取消指挥官目标点
    cancelSendCommander() {
      this.showMore.markerModal = false
      this.showMore.marker = false
      if (this.sendMarker) {
        this.map.remove(this.sendMarker)
        this.sendMarker = ''
        // this.sendMarkers.pop();
      }
      this.resetSendCommanderOptions()
      this.toggleMarkerClickOrDragEvent(false)
    },
    // 添加指挥官目标点
    addMarkerToSendCommander(position) {
      if (this.sendMarker) {
        this.map.remove(this.sendMarker)
      }
      // this.toggleeMapDrag(false);
      this.sendCommanderOptions.local_data[0].lat = position[1]
      this.sendCommanderOptions.local_data[0].lng = position[0]
      this.sendMarker = new AMap.Marker({
        position: new AMap.LngLat(position[0], position[1]),
        // icon: this.targetIcon, // 添加 Icon 图标 URL
        offset: new AMap.Pixel(-10, -10),

        icon: new AMap.Icon({
          size: new AMap.Size(30, 30), // 图标尺寸
          image: this.targetIcon, // Icon的图像
          imageSize: new AMap.Size(25, 25), // 根据所设置的大小拉伸或压缩图片
        }),
      })
      this.map.add(this.sendMarker)
      this.showMore.markerModal = true
    },
    // 更换标记点类型
    targetType(type, typeStr) {
      this.targetIcon = type
      this.changeType = false
      if (this.sendMarker) {
        this.sendMarker.setIcon(this.targetIcon)
      }
      this.sendCommanderOptions.tag = typeStr
    },
    // 点击地图事件
    clickMap(event) {
      //   let { lng, lat } = event.lnglat;
      let lng, lat
      if (this.$online) {
        lng = event.lnglat.R
        lat = event.lnglat.Q
      } else {
        lng = event.lnglat.O
        lat = event.lnglat.P
      }
      // let { x, y } = event.pixel;
      if (this.showMore.marker) {
        this.toggleMarkerClickOrDragEvent(true)
        this.sendCommanderOptions.tag = 'mark_state_navigation'
        // this.sendMarker = null;
        this.targetRemarks = ''
        this.addMarkerToSendCommander([lng, lat])
      } else {
        this.drawLineOrArea([[lng, lat]])
      }
    },
    // marker的拖拽事件
    markerDragHandler(e) {
      let { lng, lat } = e.lnglat
      let dragMarkerIndex = this.clickPointers.findIndex((item) => {
        return item[2] === e.target._amap_id
      })
      this.$set(this.clickPointers[dragMarkerIndex], '0', lng)
      this.$set(this.clickPointers[dragMarkerIndex], '1', lat)
      this.createLineOrArea()
      this.throwMapData()
    },
    // setting那边改了每个单独点的数据 map这边同步更新
    syncSinglePointList() {
      this.markList.forEach((ele, index) => {
        let content = this.getCirCleContent({
          index: index,
          text: index + 1,
          isActive: index === this.activeMarker,
        })
        ele.setContent(content)
      })
      this.addCirclrMarkerClickHandler()
    },
    // 建线marker的dom内容增加了一部分
    getCirCleContent(params) {
      let content = ''
      //   let info = this.config.singlePointList[params.index] || {
      //     pointHeight: 0,
      //     pointAngle: 0,
      //   };
      if (this.isLine) {
        // content = `
        // <div class="marker-wrapper flex">
        //   <div class="plane-info user-name flex">
        //     <div>高度:&nbsp;${parseInt(info.pointHeight)}m</div>
        //     <div>角度:&nbsp;${parseInt(info.pointAngle)}°</div>
        //   </div>
        //   <div data-index="${params.text}" class="marker-cir-wrapper flex ${
        //   params.isActive ? 'm-active' : ''
        // }">${params.text}</div>
        // </div>
        // `;
        content = `
        <div class="marker-wrapper flex">
          <div data-index="${params.text}" class="marker-cir-wrapper flex ${
          params.isActive ? 'm-active' : ''
        }">${params.text}</div>
        </div>
        `
      } else {
        content = `<div class="marker-cir-wrapper flex">${params.text}</div>`
      }
      return content
    },
    // marker 工厂
    createMarker(params) {
      let content = this.getCirCleContent(params)
      let marker = new AMap.Marker({
        content: content, // 自定义点标记覆盖物内容
        position: [params.lng, params.lat], // 基点位置
        offset: this.isLine
          ? new AMap.Pixel(-16, -57)
          : new AMap.Pixel(-14, -15),
        draggable: true,
      })
      // marker.on("click", this.markerClickHandler);
      this.addCirclrMarkerClickHandler()
      // marker.on('dragend', this.markerDragHandler);
      marker.on(
        'dragging',
        _.throttle((e) => {
          this.markerDragHandler(e)
        }, 100)
      )
      return marker
    },
    // 只添加单个原点的点击事件
    addCirclrMarkerClickHandler(index) {
      if (!this.isLine || !this.config.onEventHandler) {
        return
      }
      let list = document.getElementsByClassName('marker-cir-wrapper')
      setTimeout(() => {
        Array.prototype.forEach.call(list, (ele) => {
          //   ele.hadAddClickEvent &&
          //     ele.addEventListener('click', this.markerClickHandler);
          //   ele.hadAddClickEvent = true;
          ele.addEventListener('click', this.markerClickHandler)
        })
      }, 1000) // marker构造是个异步过程 必须延迟添加事件
    },
    // line 工厂
    createLine(isDrawByArea = false) {
      let path = this.clickPointers.map((item) => {
        return new AMap.LngLat(item[0], item[1])
      })
      if (path.length < 2) {
        return
      }
      // 现在建线需要绘制2个点之间的距离了 所以需要每隔2个点单独绘制一根线
      let cb = (i) => {
        let m1, m2
        if (i === path.length - 1 && isDrawByArea) {
          m1 = this.markList[i]
          m2 = this.markList[0]
        } else {
          m1 = this.markList[i]
          m2 = this.markList[i + 1]
        }
        let p1 = m1.getPosition(),
          p2 = m2.getPosition()
        let textPos = p1.divideBy(2).add(p2.divideBy(2))
        let distance = Math.round(p1.distance(p2))
        // if (distance > 2000) {
        //   this.$Message.error('航点间距不能超过2000米');
        // }
        let pathInner = [p1, p2]
        if (this.lineList[i]) {
          this.map.remove(this.lineList[i])
          this.map.add(this.lineList[i])
          setTimeout(() => {
            this.lineList[i].setPath(pathInner)
          }, 100)
        } else {
          // 创建折线实例
          let polyline = new AMap.Polyline({
            map: this.map,
            path: path,
            borderWeight: isDrawByArea ? 0 : 1, // 线条宽度，默认为 1
            strokeColor: isDrawByArea ? 'transparent' : '#0AEF8B', // 线条颜色
            lineJoin: 'round', // 折线拐点连接处样式
          })
          this.lineList.push(polyline)
        }
        console.log('this.lineCenterTextList[i]', this.lineCenterTextList[i])
        if (this.lineCenterTextList[i]) {
          this.lineCenterTextList[i].setText(distance + '米')
          this.lineCenterTextList[i].setPosition(textPos)
        } else {
          let text = new AMap.Text({
            text: distance + '米',
            position: textPos,
            map: this.map,
            style: {
              'background-color': 'rgba(0,0,0,0.7)',
              'border-color': '#000',
              color: '#fff',
              'border-radius': '0',
              'font-size': '12px',
            },
          })
          this.lineCenterTextList.push(text)
        }
      }
      for (let i = 0, L = path.length - 1; i < L; i++) {
        cb(i)
      }
      if (path.length > 1 && isDrawByArea) {
        // 最后再连一根线
        cb(path.length - 1)
      }
    },
    // 点击marker 更新高亮 同步setting那边activeMarker
    markerClickHandler(e) {
      if (!this.isLine || !this.config.onEventHandler) {
        return
      }
      // let ele = e.target.D.M.tg.children[0].children[1];
      let ele = e.target
      this.toggleMarkerCss(ele)
      // this.activeMarker = this.clickPointers.findIndex(item => {
      //   // return item[2] === e.target._amap_id;
      //   return item[2] === parseInt(e.target.dataset.index);
      // });
      this.activeMarker = parseInt(e.target.dataset.index) - 1
      this.$emit('updateActiveMarker', {
        activeMarker: this.activeMarker + 1,
      })
    },
    // 遍历更新所有marekr的样式
    toggleMarkerCss(activeEle) {
      let list = document.getElementsByClassName('marker-cir-wrapper')
      if (
        !activeEle &&
        this.activeMarker > -1 &&
        this.activeMarker <= list.length - 1
      ) {
        return
      }
      Array.prototype.forEach.call(list, (ele, index) => {
        if (activeEle && ele === activeEle) {
          ele.className = 'marker-cir-wrapper flex m-active'
        } else if (!activeEle && index === list.length - 1) {
          ele.className = 'marker-cir-wrapper flex m-active'
        } else {
          ele.className = 'marker-cir-wrapper flex'
        }
      })
    },
    // 计算建线数据
    calculateLine() {
      let points = this.clickPointers.map((item) => [item[0], item[1]])
      let length = AMap.GeometryUtil.distanceOfLine(points)
      return {
        lineCount: this.clickPointers.length,
        lineLength: length,
        points: points,
      }
    },
    calculateArea() {
      let points = this.clickPointers.map((item) => [item[0], item[1]])
      let airArea = Math.round(AMap.GeometryUtil.ringArea(points))
      let autoLinePoints = this.RPA_polyline.latlngs.map((item) => [
        item.lng,
        item.lat,
      ])
      let autoLineLength = Math.round(
        AMap.GeometryUtil.distanceOfLine(autoLinePoints)
      )
      console.log('当前区域面积为: ', airArea)
      console.log('当前自动规划航线长度为: ', autoLineLength)
      return {
        lineCount: this.clickPointers.length,
        airArea,
        autoLineLength,
        points: points,
      }
    },
    throwMapData() {
      if (this.isLine) {
        this.$emit('calculateLine', this.calculateLine())
      } else {
        this.$emit('calculateArea', this.calculateArea())
      }
    },

    toolHandler(type) {
      // 指点飞行和群体飞控禁止其他地图操作
      if (this.showMore.marker) {
        if (type != 'marker') {
          return
        }
      } else if (this.showMore.command) {
        if (type != 'command') {
          return
        }
      }

      if (type === 'marker') {
        if (this.showMore.map_mark) {
          this.$Notice.warning({
            title: '请先关闭地图标注功能!',
          })
          return false
        }
        this.showMore.marker = !this.showMore.marker
        this.targetName = '新增目标点'
        // 显隐左侧标记工具栏，打开地图addmark
        if (!this.showMore.markerModal) {
          this.showMore.markerModal = true
        } else {
          // 关闭左侧标记工具栏，清除
          this.showMore.markerModal = false
          if (this.sendMarker) {
            this.map.remove(this.sendMarker)
            this.sendMarker = null
            // this.sendMarkers.pop();
          }
        }

        this.toggleMarkerClickOrDragEvent(this.showMore.marker)
        return
      }
      // if (type == 'command') {
      //   console.log(111);
      //   this.showMore.command = !this.showMore.command;
      //   console.log(this.showMore.command);
      //   if (this.showMore.command) {
      //     this.rectangle = this.mouseTool.rectangle({
      //       strokeColor: 'red',
      //       strokeOpacity: 0.5,
      //       strokeWeight: 6,
      //       fillColor: 'blue',
      //       fillOpacity: 0.5,
      //       // strokeStyle还支持 solid
      //       strokeStyle: 'solid',
      //       // strokeDasharray: [30,10],
      //     });
      //   } else {
      //     this.mouseTool.close(false);
      //   }
      //   return;
      // }
      if (type === 'search') {
        this.showMore.search = !this.showMore.search
        let that = this
        let autoComplete = new AMap.Autocomplete({ input: 'mapSearch_0' })
        AMap.event.addListener(autoComplete, 'select', function (e) {
          that.map.setCenter([e.poi.location.lng, e.poi.location.lat])
        })
      }
      if (type === 'measure') {
        if (this.config.onEventHandler) {
          this.$Notice.warning({
            title: '请先关闭建线或者建图功能!',
          })
          return
        } else if (this.showMore.map_mark) {
          this.$Notice.warning({
            title: '请先关闭地图标注功能!',
          })
          return false
        }

        this.showMore.measure = !this.showMore.measure
        if (this.showMore.measure) {
          this.draw()
        } else {
          this.mouseTool.close(true) // 设为true，关闭测量工具后清除地图上的测量
        }
      }
      if (type === 'hierarchy') {
        this.showMore.hierarchy = !this.showMore.hierarchy
      }
      if (type === 'zoomIn') {
        this.map.zoomIn()
        // let zoomSize = this.map.getZoom()
        // this.$Message.success(`当前缩放等级${zoomSize}`)
      }
      if (type === 'zoomOut') {
        this.map.zoomOut()
        // let zoomSize = this.map.getZoom()
        // this.$Message.success(`当前缩放等级${zoomSize}`)
      }
      if (type === 'fix') {
        this.backCenter(this.$Center)
      }
      if (type === 'map_mark') {
        this.showMore.map_mark = !this.showMore.map_mark
        if (this.showMore.map_mark) {
          this.onMark()
        }
      } else {
        this.closeDrawing()
      }
    },
    backCenter(position) {
      this.map.setCenter(position)
      // 关闭地图tool
      this.showMore.hierarchy = false
      this.showMore.command = false
      this.showMore.search = false
      this.showMore.measure = false
      this.showMore.marker = false
      this.showMore.markerModal = false
    },
    jilinSearch(e){
      let _this = this
      let geocoder = new AMap.Geocoder()
      let address = e
      geocoder.getLocation(address, function (status, result) {
        console.log(status, result)
        if (status === 'complete' && result.geocodes.length) {
          let lnglat = result.geocodes[0].location
          // console.log("lnglat",lnglat);
          _this.map.setCenter([lnglat.lng, lnglat.lat]) //设置地图中心点
        } else {
          _this.$Message.error('根据地址查询位置失败')
        }
      })
    },
    search() {
      if (!this.searchMsg) {
        this.map.setCenter(this.$Center)
        return
      }
      let _this = this
      let geocoder = new AMap.Geocoder()
      let address = this.searchMsg
      console.log(this.searchMsg)
      geocoder.getLocation(address, function (status, result) {
        console.log(status, result)
        if (status === 'complete' && result.geocodes.length) {
          let lnglat = result.geocodes[0].location
          // console.log("lnglat",lnglat);
          _this.map.setCenter([lnglat.lng, lnglat.lat]) //设置地图中心点
        } else {
          _this.$Message.error('根据地址查询位置失败')
        }
      })
    },
    // 测距
    stadiometryFn() {
      this.measureType = 'line'
      this.draw()
    },
    // 测面积
    mSurfaceFn() {
      this.measureType = 'area'
      this.draw()
    },
    // 设置目标点
    toggleMarkerClickOrDragEvent(on = true) {
      this.canExecEvent = on
      this.map.off('click', this.clickMap)
      this.markList.forEach((marker) => {
        marker.off('dragging', this.markerDragHandler)
        marker.setDraggable(false)
      })
      if (on) {
        this.map.on('click', this.clickMap)
        this.markList.forEach((marker) => {
          // marker.on("dragging", this.markerDragHandler);
          marker.setDraggable(true)
        })
      }
    },
    // 测量绘制方法
    draw() {
      var _this = this
      switch (_this.measureType) {
        case 'line': {
          _this.ruler = _this.mouseTool.rule({
            startMarkerOptions: {
              //开始图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/start.png'),
              }),
            },
            endMarkerOptions: {
              //结束图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/end.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            midMarkerOptions: {
              //中间点图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/mid.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            lineOptions: {
              //线的样式
              strokeStyle: 'solid',
              strokeColor: '#FF4949',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
            //同 RangingTool 的 自定义 设置，缺省为默认样式
          })
          break
        }
        case 'area': {
          _this.mouseTool.measureArea({
            strokeColor: '#80d8ff',
            fillColor: '#80d8ff',
            fillOpacity: 0.3,
            //同 Polygon 的 Option 设置
          })
          break
        }
      }
    },
    clearMarks() {
      this.clickPointers = []
      this.markList = []
      this.throwMapData()
    },
    // 切换3d
    switch3D() {
      this.mapType = this.mapType === '2d' ? '3d' : '2d'
      this.clearMarks()
      this.initMap()
      this.$emit('toggleMapType', this.mapType)
    },
    // //群体飞控
    // //画完选择区域后的处理
    // ControlAreaEnd(e) {
    //   let _this = this;
    //   _this.mouseTool.close();

    //   _this.rectangle = e.obj;
    //   let Path = _this.rectangle.getPath();
    //   let UAVList = _this.config.onLineList;

    //   _this.rectangle.setOptions({
    //     strokeColor: '#4169E1',
    //     fillColor: '#87CEEB',
    //   });

    //   // 开启编辑
    //   // let polyEditor = new AMap.PolyEditor(_this.map, _this.rectangle);
    //   // polyEditor.open( );

    //   console.log('mouseTool结束:', Path);
    //   console.log(UAVList);
    //   if (UAVList.length !== 0) {
    //     _this.UAVSelectedList = [];
    //     // DroneLatitude   DroneLongitude
    //     UAVList.forEach((item, ind) => {
    //       // track_tag
    //       if (
    //         _this.rectangle.contains([item.DroneLongitude, item.DroneLatitude])
    //       ) {
    //         item.dronePilotStatus = {
    //           text: '正在连接',
    //           color: '#ECD500',
    //         };
    //         _this.UAVSelectedList.push(item);
    //       }
    //     });

    //     if (_this.UAVSelectedList.length == 0) {
    //       _this.$Notice.warning({
    //         title: '所选区域没有无人机在线!',
    //       });
    //       _this.map.remove(_this.rectangle);
    //       _this.showMore.command = false;
    //     } else {
    //       // _this.$refs.sendControlMsg
    //       //构建自定义信息窗体
    //       _this.infoWindowCommand = new AMap.InfoWindow({
    //         isCustom: true,
    //         anchor: 'middle-left',
    //         content: _this.$refs.sendControlMsg,
    //       });
    //       var lng = Path[1].lng;
    //       var lat = Path[1].lat;
    //       _this.infoWindowCommand.open(_this.map, [lng, lat]);
    //     }
    //     console.log(_this.UAVSelectedList);
    //   } else {
    //     _this.$Notice.warning({
    //       title: '暂时没有无人机在线!',
    //     });
    //     _this.map.remove(_this.rectangle);
    //     _this.showMore.command = false;
    //   }
    // },
    // 发送控制请求
    // sendControlMsgFn() {
    //   console.log('发送控制请求');
    //   this.UAVSelectedList.forEach((item, ind) => {
    //     this.UAVSelectedList[ind].airPlaneAcceptedControlStatus =
    //       'waitingAccept';
    //     this.sendMsg('control', item);
    //   });
    //   setTimeout(() => {
    //     this.ControlModal = true;
    //     this.cancelSendControlbox();
    //   }, 600);
    // },
    // // 申请远程控制
    // sendMsg(type, info) {
    //   //先解除之前的控制
    //   let stop = {
    //     type: 'control',
    //     target_uid: info.user_id,
    //     to_name: info.name,
    //     from_uid: this.userInfo.user_id,
    //     from_name: this.userInfo.name,
    //     dataType: 1,
    //   };
    //   this.sendSocketMsg(stop);
    //   //再发送新的远程控制请求
    //   setTimeout(() => {
    //     let wsParams = {
    //       type: 'control',
    //       target_uid: info.user_id,
    //       to_name: info.name,
    //       from_uid: this.userInfo.user_id,
    //       from_name: this.userInfo.name,
    //       dataType: type === 'control' ? 0 : 1,
    //     };
    //     this.sendSocketMsg(wsParams);
    //     // console.log('申请喊话控制',wsParams);
    //   }, 300);
    // },
    // 关闭发送控制请求预览框
    // cancelSendControlbox() {
    //   this.infoWindowCommand.close();
    //   this.map.remove(this.rectangle);
    //   this.showMore.command = false;
    // },
    // 取消发送控制请求
    // cancelSendControlMsg() {
    //   console.log('取消发送控制请求');
    //   this.UAVSelectedList.forEach((item, ind) => {
    //     this.UAVSelectedList[ind].airPlaneAcceptedControlStatus = 'none';
    //     this.sendMsg('stop', item);
    //   });
    // },

    //快拼任务区域==============================
    //控制画面中心点
    // 113.170981,28.15978
    //画完区域后的处理
    rapidAreaEnd(e) {
      let _this = this
      _this.mouseTool.close()
      _this.rapidMouseTool = e.obj
      let Path = _this.rapidMouseTool.getPath()
      console.log(Path)
      // 拿到数据画出区域
      this.map.remove(e.obj)
      var northEast = new AMap.LngLat(Path[1].lng, Path[1].lat)
      var southWest = new AMap.LngLat(Path[3].lng, Path[3].lat)
      var bounds = new AMap.Bounds(southWest, northEast)
      this.rapidRectangle = new AMap.Rectangle({
        bounds: bounds,
        strokeColor: '#4169E1',
        strokeWeight: 6,
        strokeOpacity: 0.5,
        strokeDasharray: [30, 10],
        // strokeStyle还支持 solid
        strokeStyle: 'dashed',
        fillColor: '#87CEEB',
        fillOpacity: 0.2,
        cursor: 'pointer',
        zIndex: 50,
      })
      this.rapidRectangle.setMap(_this.map)
      var rapidRectangleEditor = new AMap.RectangleEditor(
        _this.map,
        this.rapidRectangle
      )
      //注册航线对象
      _this.rapidPath = new AMap.Polyline({
        strokeColor: '#0f0',
        strokeWeight: 2,
        strokeOpacity: 1,
      })
      this.rapidPath.setMap(_this.map)
      this.editPath = Path
      sessionStorage.setItem('editPath', JSON.stringify(Path))
      //画航线
      _this.rapidRPAPolylineHandler(Path)
      // 开启矩形编辑
      rapidRectangleEditor.open()
      //矩形被调节后触发
      rapidRectangleEditor.on('adjust', function (event) {
        console.log('矩形被调节', event.target.getPath())
        this.editPath = []
        this.editPath = event.target.getPath()
        sessionStorage.setItem(
          'editPath',
          JSON.stringify(event.target.getPath())
        )
        _this.rapidRPAPolylineHandler(event.target.getPath())
      })
      //矩形关闭调节后触发
      rapidRectangleEditor.on('end', function (event) {
        console.log('矩形关闭调节')
      })
    },
    // 快拼矩形自动规划航线==============================
    rapidRPAPolylineHandler(data) {
      var _this = this
      console.log('快拼矩形自动规划航线', data)
      let rapidData = this.config.rapidData
      let fov = (rapidData.defaultFov || 84) / 2
      let rad = (fov * Math.PI) / 180
      let hypotenuseLength = rapidData.height * Math.tan(rad) * 2
      console.log('hypotenuseLength:', hypotenuseLength)
      let photeDis =
        hypotenuseLength * (3 / 5) * (1 - rapidData.courseOverlapRate / 100)
      console.log('photeDis:', photeDis)
      // let planeSpaceDis = hypotenuseLength * (4/5) * (1 - (rapidData.sideOverlapRate / 100));
      // console.log('planeSpaceDis:', planeSpaceDis);
      let planeSpaceDis = _this.calculateBetweenOverlapRateNum(rapidData)
      console.log('间隔', planeSpaceDis)
      let rotate = rapidData.courseAngle
      let path = []
      data.forEach((item) => {
        var L = gcj02towgs84(item.lng, item.lat)
        var A = _this.computerThatLonLat(L[0], L[1], 180, planeSpaceDis / 2)
        path.push({
          lng: wgs84togcj02(A.lng, A.lat)[0],
          lat: wgs84togcj02(A.lng, A.lat)[1],
        })
      })
      // 路径集合结果
      console.log('路径集合结果', path)
      path = _this.mapLatlng2Apoint(path)
      console.log('路径集合结果2', path)
      let pathResult = cpRPA.setOptions({
        polygon: path,
        rotate: parseFloat(rotate) || 0,
        space: parseFloat(planeSpaceDis / 2) || 5,
        // space: planeSpaceDis || 5,
      })
      //   console.log('线段', pathResult, this.rapidPath);
      // 画航线
      this.rapidPath.setPath(pathResult)
      // 更新照片数据
      let autoLineLength = Math.round(AMap.GeometryUtil.distanceOfLine(path))
      console.log('autoLineLength:', autoLineLength)
      let allPhotoCount = parseInt(autoLineLength / (photeDis || 1))
      console.log('allPhotoCount:', allPhotoCount)
      this.config.rapidData.photoGraphTimes = allPhotoCount
    },
    //更新数据
    updateRapid() {
      this.$_bus.$emit('updateRapid', this.config.rapidData)
    },
    // //计算短边
    // calculateShortEdge(isLong) {
    //     var shortEdge = 0;
    //     if (isLong) {
    //         shortEdge = 3 / Math.sqrt(3 * 3 + 2 * 2);
    //     } else {
    //         shortEdge = 2 / Math.sqrt(3 * 3 + 2 * 2);
    //     }
    //     return shortEdge;
    // },
    // //计算地面长边
    // calculateGroundLongEdge() {
    //     var edge = this.calculateShortEdge(true);
    //     var fovNum =
    //         2 *
    //         (this.config.rapidData.height - this.config.rapidData.targetHeight) *
    //         Math.tan((84 / 2.0) * (Math.PI / 180));
    //     var fovLongsideNum =
    //         2 *
    //         (this.config.rapidData.height - this.config.rapidData.targetHeight) *
    //         Math.tan((84 / 2.0) * (Math.PI / 180)) *
    //         edge;
    //     return fovLongsideNum;
    // },
    // //计算地面短边
    // calculateGroundShortEdge() {
    //     var edge = this.calculateShortEdge(false);
    //     var fovshortsideNum =
    //         2 *
    //         (this.config.rapidData.height - this.config.rapidData.targetHeight) *
    //         Math.tan(((84 / 2.0) * Math.PI) / 180) *
    //         edge;
    //     return fovshortsideNum;
    // },
    // // 计算主航线间的间隔
    // calculateBetweenOverlapRateNum() {
    //     var num = this.calculateGroundShortEdge();
    //     return num * (1 - this.config.rapidData.courseOverlapRate / 100.0);
    // },
    // //计算主航线上的间隔
    // calculateonOverlapRateNum() {
    //     var num = this.calculateGroundLongEdge;
    //     return num * (1 - this.config.rapidData.sideOverlapRate / 100.0);
    // },
    changeName(e) {
      this.showName = e
      let x = document.querySelectorAll('.if_name')
      let y = document.querySelectorAll('.if_name_drone')
      if (x.length > 0) {
        x.forEach((element) => {
          element.style.visibility = e
        })
      }
      if (y.length > 0) {
        y.forEach((element) => {
          element.style.visibility = e
        })
      }
    },

    //项目和任务列表地图显示相应的位置和图形================================================================
    projectNav_ShowMarker(listData, ProjectType) {
      this.infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -30),
      })
      this.map.clearMap()
      //创建图标,绑定事件
      if (ProjectType == 3) {
        //当是显示任务列表
        listData.forEach((item) => {
          var iconImg
          if (item.tasktype == 'airroute') {
            iconImg = require('../../../assets/img/Marker/line_green.png')
          } else if (item.tasktype == 'figure') {
            iconImg = require('../../../assets/img/Marker/pic_red.png')
          }

          if (item.firstlocal) {
            let marker = new AMap.Marker({
              icon: new AMap.Icon({
                image: iconImg,
                size: new AMap.Size(25, 40),
              }),
              offset: new AMap.Pixel(-15, -40),
              position: [
                Number(eval(item.firstlocal).lng),
                Number(eval(item.firstlocal).lat),
              ],
            })
            marker.setLabel({
              offset: new AMap.Pixel(-50, -28),
              content: `<div class="mark_label"><P>${item.filetitle}</P></div>`,
            })
            marker.content = `<div class="marker-content" id=#${item.pm_id}>
                            <ul>
                                <li><i class="ivu-icon ivu-icon-ios-home-outline"></i>${item.filetitle}</li>
                                <li><i class="ivu-icon ivu-icon-ios-time-outline"></i>${item.data_create_time}</li>
                                <li><i class="ivu-icon ivu-icon-md-person"></i>${item.name}</li>
                                <li><i class="ivu-icon ivu-icon-md-log-in"></i>点击进入任务</li>
                            </ul>
                        </div>
                          `
            marker.data = item
            marker.id = item.pm_id
            this.map.add(marker)
            marker.on('mouseover', this.projectNav_shouInfoWindow)
          }
        })
        this.map.setFitView()
      } else {
        if (ProjectType == 1) {
          var iconImg = require('../../../assets/img/Marker/Level_1_project.png')
        } else if (ProjectType == 2) {
          var iconImg = require('../../../assets/img/Marker/Level_2_project.png')
        }

        listData.forEach((item) => {
          let marker = new AMap.Marker({
            icon: new AMap.Icon({
              image: iconImg,
              size: new AMap.Size(25, 40),
            }),
            offset: new AMap.Pixel(-15, -40),
            position: [
              Number(eval(item.location)[0].lng),
              Number(eval(item.location)[0].lat),
            ],
          })
          marker.setLabel({
            offset: new AMap.Pixel(-50, -28),
            content: `<div class="mark_label"><P>${item.pm_name}</P></div>`,
          })
          marker.content = `<div class="marker-content" id=#${item.pm_id}>
                            <ul>
                                <li><i class="ivu-icon ivu-icon-ios-home-outline"></i>${item.pm_name}</li>
                                <li><i class="ivu-icon ivu-icon-ios-time-outline"></i>${item.data_create_time}</li>
                                <li><i class="ivu-icon ivu-icon-md-person"></i>${item.pm_administrator}</li>
                                <li><i class="ivu-icon ivu-icon-md-log-in"></i>点击进入项目</li>
                            </ul>
                        </div>
                          `
          marker.data = item
          marker.id = item.pm_id
          this.map.add(marker)
          marker.on('mouseover', this.projectNav_shouInfoWindow)
        })
        if (ProjectType == 2) {
          this.map.setFitView()
        }
      }
    },
    // 移入显示详细信息
    projectNav_shouInfoWindow(e) {
      var _this = this
      this.infoWindow.setContent(e.target.content)
      this.infoWindow.open(this.map, e.target.getPosition())
      // 点击信息进入工程,任务
      setTimeout(function () {
        var a = document.getElementById(`#${e.target.id}`)
        a.onclick = function () {
          _this.projectNav_IntoPproject(e.target.id, e.target.data)
        }
      }, 80)
    },
    //进入工程,任务
    projectNav_IntoPproject(pm_id, e) {
      console.log('//进入工程,任务')
      if (this.$store.state.ProjectListType == 0) {
        console.log('进入一级工程,展示二级工程列表')
        this.$_bus.$emit('Into_the_project', pm_id)
      } else if (this.$store.state.ProjectListType == 1) {
        console.log('进入二级工程,展示任务列表')
        this.$_bus.$emit('Into_the_task', pm_id)
      } else if (this.$store.state.ProjectListType == 2) {
        console.log('进入任务', pm_id, e)
        this.projectNav_IntoheTask(e)
      }
    },
    // 点击列表进入任务
    projectNav_IntoheTask(item) {
      var _this = this
      console.log('点击列表进入任务', item)
      sessionStorage.setItem('getTaskData', JSON.stringify(item))
      if (this.$route.query.Status == 2) {
        this.$router.push({
          path: '/resourceIndex/uploadingPage',
          query: {
            TaskID: item.id,
            status: 'UpZip',
          },
        })
      } else {
        this.$router.push({
          path: '/resourceIndex/uploadingPage',
          query: {
            TaskID: item.id,
            status: 'imgUpload',
          },
        })
      }
    },
    //显示建图任务区域
    ShouBuildFigure() {
      let _this = this
      this.map.clearMap()
      let Data = JSON.parse(sessionStorage.getItem('getTaskData'))
      console.log(Data)
      let local_data = Data.local_data
      var path = []
      local_data.forEach((item) => {
        path.push([item.lng, item.lat])
      })

      //画点
      // path.forEach(item=>{
      //   let marker = new AMap.Marker({
      //     position: item
      //   });
      //   marker.DATA = Data;
      //   this.map.add(marker);
      //   marker.on("click", this.shouOrthophotoMap);
      // });

      // 画多边形
      var polygon = new AMap.Polygon({
        path: path,
        strokeColor: '#FF33FF',
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        fillColor: '#1791fc',
        zIndex: 50,
      })
      polygon.DATA = Data
      this.map.add(polygon)
      // 缩放地图到合适的视野级别
      this.map.setFitView([polygon])
    },

    /**
     * 虚拟护栏 NFzone
     */
    // 初始化禁飞区
    initNFzone() {
      let that = this
      console.log('333333333333333',sessionStorage.getItem('Eid'))
      this.$post(GetNotFlyArea(), {
        team_id: that.userInfo.team_id,
       /* team_id:sessionStorage.getItem('Eid'),*/
        showTeam: 0,
      }).then((res) => {
        if (res.code === 1) {
          res.data.forEach((element) => {
            let path = []
            element.local_data.forEach((ele) => {
              path.push(
                // new AMap.LngLat(
                //   window.gcj02towgs84(ele.lng,ele.lat)[0],
                //   window.gcj02towgs84(ele.lng, ele.lat)[1]
                // )
                new AMap.LngLat(ele.lng, ele.lat)
              )
            })

            let polygon = new AMap.Polygon({
              path: path,
              fillColor: '#c41a16',
              strokeColor: '#ff0000',
              fillOpacity: 0.3,
            })

            console.log('333333333polygon333333',polygon)
            that.NFzone.push(polygon)
            that.map.add(polygon)
          })
        }
      })
    },

    // 查看飞行轨迹图片
    checkTrackImg(path) {},
    // 图片视频大图查看
    /**
     * 照片
     */
    // 查看大图
    bigImg(idx) {
      console.log(idx)
      this.bigIndex = idx
      this.imgModal = true
    },

    // 关闭弹窗
    closeImgModal() {
      this.imgModal = false
      this.bigIndex = 0
    },

    // 蒙版透明度
    changeMask(e) {
      this.mask.setOptions({ fillOpacity: 1 - this.maskOpacity / 100 })
    },
    // 切换工具栏显示
    toggleTools() {
      this.toolshow = !this.toolshow
    },

    /**
     * 地图元素标注
     */
    // 启用标注
    onMark() {
      // 获取地图标注列表
      this.getMapMarkList()
      this.getIconList()
      // 测量完成前不可点击

      if (this.drawing) {
        if (!this.drawed) {
          this.$Message.warning('请先完成当前标注')
          return
        }
        if (this.tagType != 0) {
          this.$Message.warning('请先保存当前标注')
          return
        }
        this.current = 0
        this.drawing = false
        // this.del = false;
        //   this.$Message.info('已关闭标注功能');
        // this.map.off("click", this.clickHandler);
        this.mouseTool.close()
      } else {
        // this.$Message.info('选择标注物类型');
        // this.del = false;
        this.drawing = true
        //监听draw事件可获取画好的覆盖物
        this.mouseTool.on('draw', this.getDraw)
      }
    },
    // 关闭标注
    closeDrawing() {
      if (this.drawing) {
        if (!this.drawed) {
          this.$Message.warning('请先完成当前标注')
          return
        }
        if (this.tagType != 0) {
          this.$Message.warning('请先保存当前标注')
          return
        }
        this.current = 0
        // this.drawing = false;
        // this.del = false;
        //   this.$Message.info('已关闭标注功能');
        // this.map.off("click", this.clickHandler);
        // this.mouseTool.close();
      }
      this.showMore.map_mark = false
      this.drawing = false
      if (this.mouseTool) {
        this.mouseTool.close()
      }
    },

    // 获取地图标注列表
    getMapMarkList() {
      let tid = 0
      if (
        sessionStorage.getItem('team_id') === null ||
        Number(sessionStorage.getItem('team_id')) === -1
      ) {
        tid = this.userInfo.team_id
      } else {
        tid = Number(sessionStorage.getItem('team_id'))
      }
      this.$post(Api.getMapMarkList(), {
        team_id: tid,
      }).then((res) => {
        // console.log(res);
        this.mapMarkList = res.data
      })
    },
    // 切换地图标注列表
    changeMark(e) {
      console.log(this.drawing, this.drawed, this.tagType)
      if (!this.drawed || this.tagType != 0) {
        this.cancelDraw()
      }

      if (e) {
        this.clearOverLays()
        this.markID = e.value
        this.map_mark_name = e.label
        this.getMarksDetail()
      }
    },
    // 获取标注详情
    getMarksDetail() {
      this.$post(Api.getMapMark(), {
        id: this.markID,
      }).then((res) => {
        if (res.data) {
          // this.markID = res.data ? res.data.id : "";
          if (JSON.parse(res.data.spot) == null) {
            this.overlays.spot = []
          } else {
            this.overlays.spot = JSON.parse(res.data.spot)
          }
          if (JSON.parse(res.data.line) == null) {
            this.overlays.line = []
          } else {
            this.overlays.line = JSON.parse(res.data.line)
          }
          if (JSON.parse(res.data.surface) == null) {
            this.overlays.surface = []
          } else {
            this.overlays.surface = JSON.parse(res.data.surface)
          }
          if (JSON.parse(res.data.icon) == null) {
            this.overlays.icon = []
          } else {
            this.overlays.icon = JSON.parse(res.data.icon)
          }
          console.log(this.overlays)
          this.initMark()
        }
      })
    },
    // 清除地图标注
    clearOverLays() {
      this.overlayGroup.clearOverlays()
      this.$nextTick(() => {
        this.tagType = ''
        this.overlays = {
          spot: [],
          line: [],
          surface: [],
          icon: [],
        }

        // 清空下拉选择框
      })
    },

    // 初始化标注
    initMark() {
      let _this = this
      let offsetAmount = 18,
        offsetAmount_1 = 38
      if (this.$online) {
        offsetAmount = 0
        offsetAmount_1 = 0
      }

      // 点
      this.overlays.spot.forEach((ele) => {
        let spot = new AMap.Marker({
          position: _this.$online ? [ele.p.R, ele.p.Q] : [ele.p.O, ele.p.P], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          content: `<div style='width:16px;height:16px;border-radius: 50%;border:2px solid #fff;background:#5cadff;'></div>`,
          // offset: new AMap.Pixel(-15, -36),
          offset: new AMap.Pixel(-8, -8),
          extData: { type: 1, dateID: ele.i, name: ele.name },
          label: {
            content:
              `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
              ele.name +
              `</span>`,
            direction: 'bottom',
            offset: new AMap.Pixel(0, offsetAmount),
          },
        })

        spot.dateID = spot.on('click', this.showInfoM)

        // this.map.add(spot);
        this.overlayGroup.addOverlay(spot)
      })
      // 图标
      this.overlays.icon.forEach((ele) => {
        console.log('图标', ele.img)
        let icon = new AMap.Marker({
          position: _this.$online ? [ele.p.R, ele.p.Q] : [ele.p.O, ele.p.P], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: new AMap.Icon({
            size: new AMap.Size(32, 32), // 图标尺寸
            image: ele.img, // Icon的图像
            imageSize: new AMap.Size(32, 32), // 根据所设置的大小拉伸或压缩图片
          }),
          // content: `<div style='width:9px;height:9px;border-radius: 50%;border:2px solid #fff;background:#5cadff;'></div>`,
          offset: new AMap.Pixel(-16, -32),
          // offset: new AMap.Pixel(0, 0),
          extData: { type: 4, dateID: ele.i, name: ele.name },
          label: {
            content:
              `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
              ele.name +
              `</span>`,
            direction: 'bottom',
            offset: new AMap.Pixel(0, offsetAmount_1),
          },
        })

        icon.dateID = icon.on('click', this.showInfoM)
        // this.map.add(icon);
        this.overlayGroup.addOverlay(icon)
      })
      // 行动箭头
      this.overlays.line.forEach((ele) => {
        let path = []
        ele.p.forEach((element) => {
          path.push(
            _this.$online ? [element.R, element.Q] : [element.O, element.P]
          )
        })
        let line = new AMap.Polyline({
          path: path,
          strokeColor: ele.color,
          strokeWeight: 6,
          lineJoin: 'round',
          lineCap: 'round',
          showDir: true,
          cursor: 'pointer',
        })
        let A = new AMap.Marker({
          position: path[0],
          content: ' ',
          label: {
            content:
              `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
              ele.name +
              `</span><span style=';color:#fff'>总长` +
              line.getLength() +
              `米</span>`,
            direction: 'bottom',
            offset: new AMap.Pixel(0, offsetAmount_1),
          },
        })
        line.setExtData({ type: 2, dateID: ele.i, measure: A, name: ele.name })
        line.on('click', this.showInfoM)
        // this.map.add(line);
        // this.map.add(A);
        this.overlayGroup.addOverlay(line)
        this.overlayGroup.addOverlay(A)
      })
      // 灾区
      this.overlays.surface.forEach((ele) => {
        let dA = []
        ele.p.forEach((e) => {
          dA.push(_this.$online ? [e.R, e.Q] : [e.O, e.P])
        })
        let surface = new AMap.Polygon({
          path: dA,
          fillColor: ele.color,
          strokeColor: ele.color,
          strokeWeight: 2,
          fillOpacity: 0.4,
          cursor: 'pointer',
        })
        let B = new AMap.Marker({
          position: dA[0],
          content: ' ',
          label: {
            content:
              `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
              ele.name +
              `</span><span style=';color:#fff'>面积` +
              surface.getArea() +
              `平方米</span>`,
            direction: 'bottom',
            offset: new AMap.Pixel(0, offsetAmount_1),
          },
        })
        surface.setExtData({
          type: 3,
          dateID: ele.i,
          measure: B,
          name: ele.name,
        })
        surface.on('click', this.showInfoM)
        // this.map.add(surface);
        // this.map.add(B);
        this.overlayGroup.addOverlay(surface)
        this.overlayGroup.addOverlay(B)
      })
    },

    // 绘制
    drawMapMark(type) {
      if (this.drawing) {
        if (!this.drawed) {
          this.$Message.warning('请先完成当前标注')
          return
        }
        if (this.tagType != 0) {
          this.$Message.warning('请先保存当前标注')
          return
        }

        this.current = type
        // this.ruleTool.close();
        let that = this

        switch (type) {
          case 1: {
            // 消防员
            that.mouseTool.marker({
              content: `<div style='width:16px;height:16px;border-radius: 50%;border:2px solid #fff;background:#5cadff;'></div>`,
              offset: new AMap.Pixel(-8, -8),
              extData: { type: 1 },
            })

            break
          }

          case 2: {
            this.drawed = false
            //行动箭头
            that.mouseTool.polyline({
              strokeColor: that.mycolor.xcolor,
              strokeWeight: 6, //线宽
              lineJoin: 'round',
              lineCap: 'round',
              showDir: true,
              extData: { type: 2 },
            })

            break
          }
          case 3: {
            this.drawed = false
            //灾区
            that.mouseTool.polygon({
              fillColor: that.mycolor.mcolor,
              strokeColor: that.mycolor.mcolor,
              extData: { type: 3 },
            })

            break
          }
          case 4: {
            this.drawed = false
            let imgUrl = this.markIcon[0] // 无奈之举,that.mouseTool.marker的定义在mounted就已经执行了,先选择图标再打点的图标不会更改,但也同时避免了changeMarkIcon()中this.nowObj.setIcon未定义的问题

            // if (this.$CSXF) {
            //   imgUrl = 'https://www.jointflight.cn/fireman.png';
            // } else if (this.$CSTJ) {
            //   imgUrl = 'https://www.jointflight.cn/fireman.png';
            // } else if (this.$public) {
            //   imgUrl = 'https://www.jointflight.cn/fireman.png';
            // } else {
            //   imgUrl = 'https://www.jointflight.cn/fireman.png';
            // }
            // 图标
            that.mouseTool.marker({
              icon: new AMap.Icon({
                size: new AMap.Size(32, 32), // 图标尺寸
                image: imgUrl, // Icon的图像
                imageSize: new AMap.Size(32, 32), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-16, -32),
              extData: { type: 4 },
            })

            break
          }
        }
      } else {
        this.$Message.info('请先启用标注功能')
      }
    },
    // 绘制完成后获取覆盖物
    getDraw(e) {
      this.nowObj = e.obj
      let newAction = e.obj
      this.map.remove(e.obj)
      e.obj.on('click', this.showInfoM) // 监听覆盖物点击事件
      if (e.obj.getExtData().type == 2) {
        // 重绘线段
        // this.map.remove(e.obj);

        newAction = new AMap.Polyline({
          path: e.obj.getPath(),
          strokeColor: this.mycolor.xcolor,
          strokeWeight: 6,
          lineJoin: 'round',
          lineCap: 'round',
          showDir: true,
          extData: { type: 2 },
          cursor: 'pointer',
        })
        // this.map.add(newAction);
        this.nowObj = newAction
        // newAction.on("click", this.showInfoM);
      }
      this.overlayGroup.addOverlay(newAction)
      this.tagType = this.nowObj.getExtData().type
      this.drawed = true
      this.mouseTool.close()
    },

    // 取消标注
    // offMark() {
    //   if (!this.ruled) {
    //     this.$Message.warning('请先结束当前测量');
    //     return;
    //   }

    // },

    // 选中标注
    showInfoM(e) {
      if (!this.drawed) {
        return
      }
      if (this.tagType != 0) {
        this.$Message.warning('请先保存当前标注')
        return
      }
      this.drawing = true
      this.nowObj = e.target
      this.tagType = e.target.getExtData().type
      this.tagName = e.target.getExtData().name
      this.tagId = e.target.getExtData().dateID
      switch (e.target.getExtData().type) {
        case 1:
          break
        case 2:
          this.mycolor.xcolor = e.target.getExtData().color
          break
        case 3:
          this.mycolor.mcolor = e.target.getExtData().color
          break
        // case 4:
        //   this.onImg = e.target.getExtData().img;
        //   break;
      }
    },
    //删除标注
    tagDelete(id) {
      //   if (this.$online) {

      //   this.map.getAllOverlays().forEach((e) => {
      this.overlayGroup.getOverlays().forEach((e) => {
        if (e.getExtData().dateID == id) {
          switch (e.getExtData().type) {
            case 1:
              this.overlays.spot.forEach((e, index) => {
                if (e.i == id) {
                  this.overlays.spot.splice(index, 1)
                }
              })
              break
            case 2:
              this.overlays.line.forEach((e, index) => {
                if (e.i == id) {
                  this.overlays.line.splice(index, 1)
                }
              })
              break
            case 3:
              this.overlays.surface.forEach((e, index) => {
                if (e.i == id) {
                  this.overlays.surface.splice(index, 1)
                }
              })
              break
            case 4:
              this.overlays.icon.forEach((e, index) => {
                if (e.i == id) {
                  this.overlays.icon.splice(index, 1)
                }
              })
              break
          }
          if (e.getExtData().type == 2 || e.getExtData().type == 3) {
            this.map.remove(e.getExtData().measure) // 移除覆盖物
          }
          this.tagType = ''
          this.map.remove(e) // 移除覆盖物
        }
      })
    },
    // 显示隐藏图形
    showTag(id, event) {
      //   if (this.$online) {
      //   this.map.getAllOverlays().forEach((e) => {
      this.overlayGroup.getOverlays().forEach((e) => {
        if (e.getExtData().dateID == id) {
          if (e.getVisible()) {
            e.hide()
            if (e.getExtData().type == 2 || e.getExtData().type == 3) {
              e.getExtData().measure.hide()
            }
            event.target.className = 'ivu-icon ivu-icon-ios-eye-off'
          } else {
            e.show()
            if (e.getExtData().type == 2 || e.getExtData().type == 3) {
              e.getExtData().measure.show()
            }
            event.target.className = 'ivu-icon ivu-icon-ios-eye'
          }
        }
      })
    },
    // 获取图标
    getIconList() {
      this.$post(ModelIconList(), {
        team_id: 1,
        type: '2d',
        is_share: 2,
      }).then((res) => {
        this.markIcon = []
        res.data.cloud_image_list.forEach((element) => {
          this.markIcon.push(element.path)
        })
        this.onImg = this.markIcon[0]
      })
    },
    // 选择图标
    changeMarkIcon(idx) {
      this.markIconType = idx
      this.onImg = this.markIcon[idx]

      this.nowObj.setIcon(
        new AMap.Icon({
          size: new AMap.Size(32, 32), // 图标尺寸
          image: this.onImg, // Icon的图像
          imageSize: new AMap.Size(32, 32), // 根据所设置的大小拉伸或压缩图片
        })
      )
    },
    // 上传标注
    save() {
      if (!this.drawed) {
        this.$Message.warning('请先完成当前标注')
        return
      }
      if (this.tagType != 0) {
        this.$Message.warning('请先保存当前标注')
        return
      }
      if (this.isSaveing) {
        return false
      }

      let tid = 0
      if (
        sessionStorage.getItem('team_id') === null ||
        Number(sessionStorage.getItem('team_id')) === -1
      ) {
        tid = this.userInfo.team_id
      } else {
        tid = Number(sessionStorage.getItem('team_id'))
      }
      this.isSaveing = true
      this.$post(Api.addMapMark(), {
        spot: JSON.stringify(this.overlays.spot),
        line: JSON.stringify(this.overlays.line),
        surface: JSON.stringify(this.overlays.surface),
        icon: JSON.stringify(this.overlays.icon),
        id: this.markID,
        team_id: tid,
        mark_name: this.map_mark_name,
      })
        .then((res) => {
          this.isSaveing = false
          console.log(res)
          if (res.code == 1) {
            this.$Message.success(res.msg_customer)
          } else {
            this.$Message.error(res.msg_customer)
          }
        })
        .catch(() => {
          this.isSaveing = false
        })
    },
    // 另存为
    saveAs() {
      // this.markID = "";
      this.map_mark_name = ''
      //   if (this.map_mark_name == '') {
      // 新建标注,需命名
      this.map_mark_name_modal = true

      // return false;
      //   }
      //   this.save();
    },
    // 确认另存为
    saveAsOk() {
      if (this.map_mark_name == '') {
        this.$Message.warning('标注集合名称不能为空')
      } else {
        this.save()
      }
    },
    // rightClick() {
    //   console.log('点击了右键');
    // },
    // 切换颜色
    headleChangeColor(e) {
      console.log('切换颜色')
      if (e == 1) {
        this.nowObj.setOptions({
          fillColor: this.mycolor.mcolor,
          strokeColor: this.mycolor.mcolor,
        })
      } else if (e == 2) {
        this.nowObj.setOptions({
          strokeColor: this.mycolor.xcolor,
        })
      }
    },
    //选择图标
    cusimg(e) {
      this.onImg = e
      this.nowObj.setIcon(
        new AMap.Icon({
          size: new AMap.Size(30, 36), // 图标尺寸
          image: e, // Icon的图像
          imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
        })
      )
    },
    //保存标注
    saveDraw() {
      let date = new Date().getTime()
      let that = this
      let offsetAmount = 18,
        offsetAmount_1 = 38
      if (this.$online) {
        offsetAmount = 0
        offsetAmount_1 = 0
      }
      //修改标注
      if (this.tagName == '') {
        this.$Message.warning('请输入标注信息')
        return
      }
      switch (that.nowObj.getExtData().type) {
        case 1:
          this.overlays.spot.forEach((e, index) => {
            if (e.i == that.nowObj.getExtData().dateID) {
              this.overlays.spot.splice(index, 1)
            }
          })
          break
        case 2:
          this.overlays.line.forEach((e, index) => {
            if (e.i == that.nowObj.getExtData().dateID) {
              this.overlays.line.splice(index, 1)
            }
          })
          break
        case 3:
          this.overlays.surface.forEach((e, index) => {
            if (e.i == that.nowObj.getExtData().dateID) {
              this.overlays.surface.splice(index, 1)
            }
          })
          break
        case 4:
          this.overlays.icon.forEach((e, index) => {
            if (e.i == that.nowObj.getExtData().dateID) {
              this.overlays.icon.splice(index, 1)
            }
          })
          break
      }
      switch (this.nowObj.getExtData().type) {
        case 1:
          that.nowObj.setLabel({
            content:
              `<span style='text-align:center;display:block;font-size:16px;font-weight: bold;color:#fff'>` +
              that.tagName +
              `</span>`,
            direction: 'bottom',
            offset: new AMap.Pixel(0, offsetAmount),
          })
          that.nowObj.setExtData({ type: 1, dateID: date, name: that.tagName })
          that.overlays.spot.push({
            p: that.nowObj.getPosition(),
            i: date,
            name: that.tagName,
          })
          break
        case 2:
          let A = new AMap.Marker({
            position: that.nowObj.getPath()[0],
            content: ' ',
            label: {
              content:
                `<span style='text-align:center;display:block;font-weight: bold;font-size:16px;color:#fff'>` +
                that.tagName +
                `</span><span style=';color:#fff'>总长` +
                that.nowObj.getLength() +
                `米</span>`,
              direction: 'bottom',
              offset: new AMap.Pixel(0, offsetAmount_1),
            },
          })
          //   that.nowObj.setLabel({
          //     content:
          //       `<span style='text-align:center;display:block;font-weight: bold;font-size:16px;color:#fff'>` +
          //       that.tagName +
          //       `</span><span style=';color:#fff'>总长` +
          //       that.nowObj.getLength() +
          //       `米</span>`,
          //     direction: 'bottom',
          //   });
          // that.map.add(A);
          that.overlayGroup.addOverlay(A)

          that.nowObj.setExtData({
            type: 2,
            dateID: date,
            name: that.tagName,
            color: that.mycolor.xcolor,
            measure: A,
          })
          that.overlays.line.push({
            p: that.nowObj.getPath(),
            i: date,
            name: that.tagName,
            color: that.mycolor.xcolor,
          })
          break
        case 3:
          let B = new AMap.Marker({
            position: that.nowObj.getPath()[0],
            content: ' ',
            label: {
              content:
                `<span style='text-align:center;display:block;font-weight: bold;color:#fff';font-size:16px;color:#fff>` +
                that.tagName +
                `</span><span style=';color:#fff'>面积` +
                that.nowObj.getArea() +
                `平方米</span>`,
              direction: 'bottom',
              offset: new AMap.Pixel(0, offsetAmount_1),
            },
          })
          // that.map.add(B);
          that.overlayGroup.addOverlay(B)
          that.nowObj.setExtData({
            type: 3,
            dateID: date,
            name: that.tagName,
            color: that.mycolor.mcolor,
            measure: B,
          })
          that.overlays.surface.push({
            p: that.nowObj.getPath(),
            i: date,
            name: that.tagName,
            color: that.mycolor.mcolor,
          })
          break
        case 4:
          that.nowObj.setLabel({
            content:
              `<span style='text-align:center;display:block;font-weight: bold;color:#fff;font-size:16px;'>` +
              that.tagName +
              `</span>`,
            direction: 'bottom',
            offset: new AMap.Pixel(0, offsetAmount_1),
          })
          that.nowObj.setExtData({
            type: 4,
            dateID: date,
            name: that.tagName,
            img: that.onImg,
            // img: 'https://www.jointflight.cn/fireman.png',
          })
          that.overlays.icon.push({
            p: that.nowObj.getPosition(),
            i: date,
            name: that.tagName,
            img: that.onImg,
            // img: 'https://www.jointflight.cn/fireman.png',
          })
          break
        default:
          break
      }

      // console.log(this.overlays);
      this.tagName = ''
      this.current = 0
      this.tagType = 0
    },
    //取消保存
    cancelDraw() {
      console.log(this.nowObj, this.nowObj != '')
      if (this.nowObj != '') {
        if (!this.nowObj.getExtData().dateID) {
          this.map.remove(this.nowObj)
        }
      }
      this.nowObj = {}
      this.tagName = ''
      this.current = 0
      this.tagType = 0
      this.tagId = ''
    },
    //获取一张图坐标点
    LDResource(type) {
      let modalType = type //区分航线3D2D全景
      let that = this
      this.$post(Api.loadAllResource(), {
        team_id: JSON.parse(sessionStorage.getItem('user_info')).team_id,
        type: 0,
        bf_name: '',
        page: 1,
        limit: 100,
      })
        .then((res1) => {
          this.$post(PanoramawebList(), {
            page: 1,
            limit: 100,
            team_id: JSON.parse(sessionStorage.getItem('user_info')).team_id,
          })
            .then((res2) => {
              let panoramaList = res2.data.data
              panoramaList.forEach((item) => {
                item.mode_type = 3 //标识全景数据
              })
              let dataList = [...panoramaList, ...res1.data.data[0].data_list] //拼接数组
              that.initModalMark(
                dataList, //3D2D全景数据
                res1.data.data[0].air_line.tasklist, //航线数据
                modalType
              )
            })
            .catch((err) => {
              // console.log('err=====',err);
              // this.$Message.error(err)
            })
        })
        .catch((err) => {
          // console.log('err=====',err);
          // this.$Message.error(err)
        })
    },
    //删除坐标点
    delMarkers(markT) {
      if (markT == 'airLine') {
        this.map.remove(this.lineMarkList)
      }
      if (markT == 'panorama') {
        this.map.remove(this.panoramaMarkList)
      }
      if (markT == 'twoDMark') {
        this.map.remove(this.twoDMarkList)
      }
      if (markT == 'threeDMark') {
        this.map.remove(this.threeDMarkList)
      }
    },
    //渲染坐标点
    initModalMark(data, linedata, modalType) {
      let that = this
      if (modalType === 1) {
        linedata.forEach((ele) => {
          //航线
          let lineMark = new AMap.Marker({
            position: [
              Number(ele.waypoint[0].lng),
              Number(ele.waypoint[0].lat),
            ], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            label: {
              offset: new AMap.Pixel(-15, -5), //设置文本标注偏移量
              content:
                "<div class='info'>" +
                `${ele.name ? ele.name : '暂无名称'}` +
                '</div>', //设置文本标注内容
              direction: 'top', //设置文本标注方位
            },
            icon: new AMap.Icon({
              size: new AMap.Size(60, 60), // 图标尺寸
              image: line, // Icon的图像
              imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
            }),
            offset: new AMap.Pixel(-15, -15),
            extData: { dateID: ele.id },
          })
          lineMark.on('click', () => that.showInfoAL(ele)) //点击图标触发点击事件
          that.lineMarkList.push(lineMark) //将每次绘制的图标缓存到该数组中
          that.map.add(lineMark) //渲染图标
        })
      }
      data.forEach((ele) => {
        if (ele.lng) {
          if (modalType === 2) {
            //全景
            if (ele.mode_type == 3) {
              let panoramaMark = new AMap.Marker({
                position: [Number(ele.lng), Number(ele.lat)], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                label: {
                  offset: new AMap.Pixel(-15, -5), //设置文本标注偏移量
                  content: "<div class='info'>" + ele.name + '</div>', //设置文本标注内容
                  direction: 'top', //设置文本标注方位
                },
                icon: new AMap.Icon({
                  size: new AMap.Size(60, 60), // 图标尺寸
                  image: parpng, // Icon的图像
                  // image: ele.img,
                  imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
                }),
                offset: new AMap.Pixel(-15, -15),
                extData: { dateID: ele.id },
              })
              panoramaMark.on('click', that.toPSV)
              that.panoramaMarkList.push(panoramaMark)
              that.map.add(panoramaMark)
            }
          } else if (modalType === 3) {
            if (ele.mode_type == 2) {
              //正射
              let twoDMark = new AMap.Marker({
                position: [Number(ele.lng), Number(ele.lat)], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                label: {
                  offset: new AMap.Pixel(-15, -5), //设置文本标注偏移量
                  content: "<div class='info'>" + ele.mapname + '</div>', //设置文本标注内容
                  direction: 'top', //设置文本标注方位
                },
                icon: new AMap.Icon({
                  size: new AMap.Size(60, 60), // 图标尺寸
                  image: png2D, // Icon的图像
                  // image: ele.img,
                  imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
                }),
                offset: new AMap.Pixel(-15, -15),
                extData: { dateID: ele.kmlmsg[0].kml_id },
              })
              twoDMark.on('click', that.showInfoIt2)
              that.twoDMarkList.push(twoDMark)
              that.map.add(twoDMark)
            }
          } else if (modalType === 4) {
            if (ele.mode_type == 1) {
              //三维
              let threeDMark = new AMap.Marker({
                position: [Number(ele.lng), Number(ele.lat)], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                label: {
                  offset: new AMap.Pixel(-15, -5), //设置文本标注偏移量
                  content: "<div class='info'>" + ele.mapname + '</div>', //设置文本标注内容
                  direction: 'top', //设置文本标注方位
                },
                icon: new AMap.Icon({
                  size: new AMap.Size(60, 60), // 图标尺寸
                  image: png3D, // Icon的图像
                  // image: ele.img,
                  imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
                }),
                offset: new AMap.Pixel(-15, -15),
                extData: { dateID: ele.id },
              })
              threeDMark.on('click', that.showInfoIt1)
              that.threeDMarkList.push(threeDMark)
              that.map.add(threeDMark)
            }
          }
        }
      })
      let arr = [
        ...this.lineMarks,
        ...this.panoramaMarks,
        ...this.twoDMarks,
        ...this.threeDMarks,
      ]
      this.map.setFitView(arr)
    },
    showInfoAL(e) {
      sessionStorage.setItem('getTaskData', JSON.stringify(e))
      this.$router.push({
        path: '/index/PT/TaskBar',
      })
    },
    showInfoIt1(e) {
      this.$router.push({
        name: 'ModelList',
        query: { id: e.target.getExtData().dateID },
      })
    },
    showInfoIt2(e) {
      this.$router.push({
        path: '/orthographicShou',
        query: { id: e.target.getExtData().dateID },
      })
    },
    toPSV(e) {
      this.$router.push({
        name: 'EdtiPanorama',
        query: { id: e.target.getExtData().dateID, watch: 2 },
      })
    },
    //根据vuex状态按需渲染markers
    initMarkersState() {
      if (this.$store.state.airLineState) {
        this.mapLine = ['航线']
      } else {
        this.mapLine = []
      }
      if (this.$store.state.panoramaState) {
        this.mapPanorama = ['全景照片']
      } else {
        this.mapPanorama = []
      }
      if (this.$store.state.twoMarkState) {
        this.mapTwoDMark = ['二维正射']
      } else {
        this.mapTwoDMark = []
      }
      if (this.$store.state.threeMarkState) {
        this.mapThreeDMark = ['三维模型']
      } else {
        this.mapThreeDMark = []
      }
    },
    getMapZoom() {
      let zoom = this.map.getZoom()
      this.$store.state.mapZoom = zoom
      console.log(
        'this.$store.state.mapZoom = zoom',
        (this.$store.state.mapZoom = zoom)
      )
    },
  },
}
</script>
<style lang="scss">
.goBack {
  width: 0.78rem;
  height: 0.78rem;
  position: absolute;
  top: 0.3rem;
  left: 3.1rem;
  img {
    width: 100%;
    height: 100%;
  }
}

.marker-cir-wrapper {
  width: 0.3rem;
  height: 0.3rem;
  border: 4px solid #2196f3;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  font-size: 16px;
  background-color: #fff;
  color: #000;
}

.m-active {
  border-color: #e90000;
}

.marker-wrapper {
  flex-direction: column;
  justify-content: center;
}

.marker-wrapper .plane-info div {
  white-space: nowrap;
}

.marker-wrapper .user-name {
  background: #333;
  opacity: 0.6;
  border-radius: 2px;
  margin-bottom: 4px;
  color: #fff;
  padding: 4px 8px;
  white-space: pre;
}

.marker-wrapper .user-avatar {
  overflow: hidden;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 50%;
  opacity: 1;
}

.marker-wrapper .user-avatar img {
  width: 30px;
  height: 30px;
}

.marker-wrapper .plane-info {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.marker-wrapper .plane-power-wrapper {
  width: 72px;
  height: 6px;
  background: rgba(255, 232, 228, 1);
  opacity: 1;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
}

.marker-wrapper .plane-power-wrapper .plane-power {
  width: 100px;
  height: 6px;
  background: rgba(255, 104, 91, 1);
  opacity: 1;
  border-radius: 5px;
}

.marker-wrapper .plane-avatar img {
  width: 32px;
  height: 32px;
}

.ControlModal1 {
  .ivu-modal-body {
    background-color: #e0e0e0;
  }
}

// 无人机图标样式
.drone-icon {
  position: relative;
  width: 0.32rem;
  height: 0.32rem;
  white-space: pre;

  &:hover > .drone-info {
    display: flex;
  }

  .drone-info {
    min-width: 2rem;
    height: 90px;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 14px;
    padding: 0.08rem;
    position: absolute;
    left: -175px;
    bottom: -95px;
    display: none;

    > div {
      display: flex;
      margin-bottom: 0.06rem;
      align-items: center;
    }

    .drone-info-1 {
      justify-content: space-between;

      img {
        display: inline;
        width: 0.22rem;
        height: 0.22rem;
        border-radius: 50%;
        border: 1px solid #ff685b;
        margin-right: 0.06rem;
        vertical-align: middle;
      }
    }

    .drone-info-2 {
      justify-content: flex-start;

      img {
        display: inline;
        height: 0.13rem;
      }

      span {
        margin-left: 0.03rem;
      }
    }

    .drone-info-3 {
      img {
        display: inline;
        height: 0.11rem;
        margin-right: 0.05rem;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.mapWrapper {
  width: 100%;
  height: 100%;
  // z-index: 0;
  // position: relative;

  .commonMap {
    width: 100%;
    height: 100%;
  }

  .tool-box-wrapper {
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;
    transition: all 0.3s ease-in-out;

    .tool-box {
      margin-top: 20px;
      width: 30px;
      height: 30px;
      background: #000;
      opacity: 0.7;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;

      &:hover {
        opacity: 1;
      }

      img {
        width: 18px;
      }

      .more-actions {
        width: 180px;
        // height: 240px;
        position: absolute;
        color: #fff;
        bottom: 0;
        right: 35px;
        display: flex;
        flex-direction: column;

        .more-actions-module {
          background-color: rgba(0, 0, 0, 0.7);
          padding: 13px 28px 24px;
          .act-line {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            font-size: 14px;
            &:last-child {
              margin-bottom: 0;
            }
            input {
              margin-right: 13px;
              width: 16px;
              height: 16px;
            }
            span {
              white-space: nowrap;
            }
          }
        }
        .mask-slider {
          padding: 2px 14px;
          margin-bottom: 10px;
        }
        .tile {
          padding: 24px 28px 13px;
          border-bottom: 1px solid #707070;
        }
      }

      .measureBox {
        height: 30px;
        padding: 0;
        background-color: transparent;
        flex-direction: row;

        > div {
          width: 0.85rem;
          white-space: nowrap;
          background-color: #000;
          opacity: 0.6;
          border-radius: 2px;
          margin-right: 10px;
          padding: 0.03rem 0;
          text-align: center;

          span {
            font-size: 16px;
            color: #fff;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        :nth-child(1) {
          opacity: 1;
        }
      }
    }
  }

  .searchBox {
    position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    top: 0.3rem;
    width: 100%;
    height: 0.5rem;
    #mapSearch_0 {
      height: 0.4rem;
      border-radius: 0.5rem;
      border: none;
      padding-left: 0.55rem;
      width: 5.5rem;
      margin: 0 auto;
      display: block;
      box-shadow: 0 0 4px #000;
    }
    // /deep/ .ivu-input {
    //   height: 0.4rem;
    //   border-radius: 0.5rem;
    //   border: none;
    //   padding-left: 0.55rem;
    // }

    // /deep/ .ivu-input:focus {
    //   outline: none;
    //   box-shadow: 0 0 0 2px transparent;
    //   border: 1px solid #409eff;
    // }

    // /deep/ .ivu-input-prefix,
    // .ivu-input-suffix {
    //   width: 0.6rem;
    //   height: 0.4rem;
    //   line-height: 0.4rem;

    //   i {
    //     font-size: 0.25rem;
    //     line-height: 0.4rem;
    //   }
    // }
  }

  .switch-3d {
    width: 0.5rem;
    height: 0.5rem;
    background: rgba(51, 51, 51, 1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 0.1rem;
    position: absolute;
    top: 0.2rem;
    cursor: pointer;

    img {
      width: 0.3rem;
      height: 0.3rem;
    }
  }

  .sendCommander {
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 240px;
    height: 100%;
    background: $xf_hui1;
    color: $font_color_1;
    font-size: 12px;

    > div {
      padding-left: 16px;
      border-bottom: 1px solid $black;
    }

    .top {
      font-size: 14px;
      height: 0.4rem;
      line-height: 0.4rem;
    }
    .no-data {
      color: $font_color_1;
      margin-top: 15px;
      padding-bottom: 15px;
    }

    .coordinate {
      padding: 18px 16px;

      p {
        font-size: 14px;
        margin-bottom: 10px;
      }

      span {
        display: inline-block;
        width: 170px;
        height: 24px;
        background: $xf_hui5;
        border: 1px solid $xf_hui6;
        color: $font_color_1;
        margin-bottom: 8px;
        margin-left: 8px;
        padding-left: 10px;
      }
    }

    .mark_info {
      label {
        vertical-align: top;
      }

      input,
      textarea {
        // display: inline-block;
        width: 170px;
        height: 24px;
        margin-bottom: 8px;
        margin-left: 8px;
        padding-left: 10px;
        outline: 0;
        background: $xf_hui5;
        border: 1px solid $xf_hui6;
        color: $font_color_1;
      }

      textarea {
        height: 0.72rem;
        resize: none;
      }
    }

    .mark_style {
      position: relative;
      border-bottom: 0;
      .target-icon {
        // width: 32px;
        // height: 32px;
        background: $xf_hui5;
        background-color: 1px solid $xf_hui6;
        display: inline-block;
        padding: 4px;
        vertical-align: middle;
        margin: 0 20px 0 8px;
        border-radius: 2px;
        img {
          width: 24px;
        }
      }
      button {
        width: 36px;
        height: 24px;
        background: $blue;
        color: #fff;
        cursor: pointer;
      }
      .icons {
        position: absolute;
        top: 88px;

        li {
          float: left;
          padding: 5px;
          background: #dbdbdb;
        }
      }
      .btns {
        .sendBtn {
          display: inline-block;
          width: 88px;
          height: 28px;
          background: $blue;
          color: #fff;
          margin-left: 8px;
          margin-top: 25px;
          text-align: center;
          line-height: 28px;
          cursor: pointer;
          border: 1px solid $blue;
        }
        .sendCancel {
          background-color: $xf_hui5;
          color: $white;
          margin-left: 16px;
          border: 1px solid #777;
        }
        .sendBtn:hover {
          background: $blue_hover;
        }
        .sendCancel:hover {
          background: $xf_hui5_hover;
        }
      }
    }

    .btns {
      justify-content: flex-start;
      font-size: 14px;

      button {
        height: auto;
        line-height: 1;
        font-size: 14px;
        padding: 0.1rem 0.16rem;
      }

      .check-all {
        transform: scale(0.8);
        white-space: nowrap;
      }
    }

    .sendControlMsg {
      padding: 0.2rem 0.15rem;
      background-color: #f6f6f6;
      border-radius: 0.1rem;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

      i {
        position: absolute;
        top: 0.05rem;
        right: 0.05rem;
        font-size: 0.3rem;
        color: #111;
        cursor: pointer;
      }

      h1 {
        font-size: 16px;
        color: #111;
        margin-top: 0.1rem;
      }

      .dronePilotBox::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }

      .dronePilotBox {
        overflow: auto;
        min-width: 2.4rem;
        max-height: 4rem;

        .dronePilot {
          min-width: 2.4rem;
          height: 0.8rem;
          margin: 0.1rem 0;
          padding: 0.1rem;
          border-radius: 0.1rem;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

          .headImg {
            width: 0.55rem;
            height: 0.55rem;
            border-radius: 50%;
          }

          .Divider {
            width: 2px;
            height: 100%;
            margin: 0 10px;
            background-color: #e0e0e0;
          }

          .msgBox {
            h2 {
              font-size: 0.18rem;
              color: #111;
            }

            h3 {
              font-size: 12px;
              color: #111;
            }
          }
        }
      }
    }

    /deep/ .amap-marker-label {
      background: rgba(0, 0, 0, 0.7);
      padding: 0;
      color: #fff;
      border: 0;

      div {
        padding: 2px 4px;
      }
    }

    .show_name {
      position: absolute;
      top: 0.1rem;
      background-color: #fff;
      transition: all 0.3s ease-in-out;
    }
  }
  .tagging {
    position: absolute;
    left: 0;
    top: 0;
    width: 228px;
    height: 100%;
    font-size: 14px;
    z-index: 250;
    background-color: $xf_hui7;
    color: $font_color_1;
    // border: 1px solid #ccc;
    // border-radius: 10px 0 0 10px;
    > ul > p {
      padding: 10px;
      font-weight: bold;
      font-size: 16px;
      border-top: 2px solid #aaa;
    }
    // text-align: center;
    .bzd {
      line-height: 20px;
      span {
        display: inline-block;
        width: 48px;
        text-align: center;
      }
    }
    .mark-rail {
      margin: 10px auto 30px;
      //   width: 230px;
      background: $xf_hei1;
      border: 1px solid $white;
      border-radius: 3px;
      height: 140px;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      > div {
        width: 60px;
        height: 40px;
        margin: 10px 0;
        img {
          width: 40px;
          margin: 0 10px;
          cursor: pointer;
        }
      }
    }
    .tagdiv {
      width: 100%;
      height: 5rem;
      overflow: auto;
    }
    .tagdel {
      background: $xf_hui1;
      border-top: solid 1px $xf_hui2;
      height: 55px;
      .center {
        float: left;
        width: 60%;
        height: 50px;
        div {
          line-height: 22px;
        }
        div:first-child {
          font-weight: bold;
        }
      }
    }

    li {
      padding: 5px 15px;
      list-style: none;
      // border-bottom: 1px solid #ccc;
      vertical-align: middle;
      line-height: 40px;
      // width: 33%;
      // float: left;
      // text-align: center;
      img {
        cursor: pointer;
        width: 36px;
        display: inline;
        margin: 0 6px;
        vertical-align: middle;
      }
    }
  }

  .taggingMore {
    position: absolute;
    left: 228px;
    top: 0;
    width: 228px;
    height: 100%;
    font-size: 14px;
    z-index: 250;
    background-color: $xf_hui1;
    color: $font_color_1;
    // border: 1px solid #ccc;
    // border-radius: 10px 0 0 10px;
    li {
      padding: 11px 15px;
    }
    li:not(:first-child) {
      border-top: 2px solid $xf_hei2;
    }
    .color {
      display: inline-block;
      padding: 10px;
      div {
        top: 2px;
      }
    }
  }
}
</style>

<template>
  <div style="position: fixed; top: 0; left: 0; width: 100%; z-index: 9999">
    <div class="aside">
      <p v-if="imgTime" class="img-modal-time">
        <span
          v-show="imgRemark"
          style="margin-right: 15px; font-weight: bolder"
          >{{ imgRemark }}</span
        >拍摄时间:{{ getLocalTime(imgTime) }}
      </p>
      <div v-else class="img-modal-time"></div>
      <Icon
        type="md-close"
        color="white"
        class="close"
        size="30"
        @click="closeImgModal()"
      />
      <v-zoomer
        v-show="bigImgUrl"
        style="width:16.65rem;height:7rem';margin:0 auto"
      >
        <div class="left">
          <img
            :src="bigImgUrl"
            class="img"
            ref="imgDiv"
            @mousedown="move"
            @mousewheel="rollImg($event)"
            id="ScrollImg"
            style="width:1080px;height:770px"
          />
        </div>
      </v-zoomer>
      <!-- <viewer :images="imgList">
        <div v-for="(el, index) in imgList" :key="index">
          <img :src="el.path" />
        </div>
      </viewer> -->
      <!-- <div class="big-img-box"
           v-show="bigImgUrl"
           :style="{background: 'url(' + bigImgUrl + ')'}">{{bigImgUrl}}</div> -->
      <div class="arrow" @click="backImg()" style="left: 0.1rem">
        <Icon type="ios-arrow-back" color="white" size="24" />
      </div>
      <div class="arrow" @click="forwardImg()" style="right: 0.1rem">
        <Icon type="ios-arrow-forward" color="white" size="24" />
      </div>
      <div class="big-list-box">
        <ul>
          <li v-for="(l, index) in imgList" :key="index">
            <img
              v-if="l.imgID"
              :src="configHeight == '8rem' ? l.path : l.thumb"
              @click="chooseImg(index)"
            />
            <img v-else :src="l.path" @click="chooseImg(index)" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bigImgModel',
  props: {
    imgList: {
      type: Array,
    },
    idx: {
      type: Number,
    },
    configHeight: {
      type: String,
    },
  },
  data() {
    return {
      bigImgUrl: '',
      bigIndex: 0,
      imgTime: '',
      imgRemark: '',
    }
  },
  mounted() {
    document.documentElement.style.overflowY = 'hidden'
    this.bigImgUrl = this.imgList[this.idx].path
    this.imgTime = this.imgList[this.idx].createTime
    this.imgRemark = this.imgList[this.idx].remark
    this.bigIndex = this.idx
    setTimeout(() => {
      this.changeBigListBoxChoose(this.idx)
    }, 100)
  },
  methods: {
    // 拖动图片
    move(e) {
      e.preventDefault()
      // 获取元素
      let left = document.querySelector('.left')
      let img = document.querySelector('.img')
      let x = e.pageX - img.offsetLeft
      let y = e.pageY - img.offsetTop
      // 添加鼠标移动事件
      left.addEventListener('mousemove', move)
      function move(e) {
        img.style.left = e.pageX - x + 'px'
        img.style.top = e.pageY - y + 'px'
      }
      // 添加鼠标抬起事件，鼠标抬起，将事件移除
      img.addEventListener('mouseup', function () {
        left.removeEventListener('mousemove', move)
      })
      // 鼠标离开父级元素，把事件移除
      left.addEventListener('mouseout', function () {
        left.removeEventListener('mousemove', move)
      })
    },
    // 缩放图片
    rollImg(event) {
      let delta = 0
      if (!event) event = window.event
      if (event.wheelDelta) {
        delta = event.wheelDelta / 120
        if (window.opera) delta = -delta
      } else if (event.detail) {
        delta = -event.detail / 3
      }
      var img = document.getElementById('ScrollImg')
      console.log(img.width)
      if (delta > 0) {
        let width = img.width
        // img.width = width * 1.05
        $('#ScrollImg').css('width', width * 1.1);
      } else if (delta < 0) {
        let width = img.width
        if (width > 400) {
          // img.width = width * 0.95
          $('#ScrollImg').css('width', width * 0.9);
        }
      }
    },

    chooseImg(idx) {
      this.bigImgUrl = ''
      this.bigIndex = idx
      this.bigImgUrl = this.imgList[idx].path
      this.imgTime = this.imgList[this.idx].createTime
      this.imgRemark = this.imgList[this.idx].remark

      this.changeBigListBoxChoose(idx)
    },
    forwardImg() {
      if (this.bigIndex < this.imgList.length - 1) {
        this.bigImgUrl = ''
        this.bigIndex++
        this.bigImgUrl = this.imgList[this.bigIndex].path
        this.imgTime = this.imgList[this.bigIndex].createTime
        this.imgRemark = this.imgList[this.bigIndex].remark

        this.changeBigListBoxChoose(this.bigIndex)
      }
    },
    backImg() {
      if (this.bigIndex > 0) {
        this.bigImgUrl = ''
        this.bigIndex--
        this.bigImgUrl = this.imgList[this.bigIndex].path
        this.imgTime = this.imgList[this.bigIndex].createTime
        this.imgRemark = this.imgList[this.bigIndex].remark

        this.changeBigListBoxChoose(this.bigIndex)
      }
    },
    // big-list-box的选中样式
    changeBigListBoxChoose(n) {
      let lis = document.querySelectorAll('.big-list-box>ul>li')
      lis.forEach((e) => {
        e.setAttribute('class', '')
      })
      lis[n].setAttribute('class', 'active')
    },
    // 关闭弹窗
    closeImgModal() {
      document.documentElement.style.overflowY = 'auto'
      this.$emit('closeBigImgModel')
    },

    getLocalTime(nS) {
      return new Date(parseInt(nS)).toLocaleString().replace(/:\d{1,2}$/, ' ')
    },
  },
}
</script>

<style lang="scss" scoped>
.aside {
  //   position: absolute;
  //   z-index: 400;
  width: 100%;
  height: 100vh;
  //   top: 0;
  //   left: 0;
  background-color: rgba(23, 23, 23, 0.84);
  .img-modal-time {
    width: 100%;
    color: #fff;
    font-size: 0.16rem;
    text-align: center;
    height: 0.3rem;
    line-height: 0.3rem;
  }
  .close {
    position: absolute;
    top: 0.15rem;
    right: 0.15rem;
    cursor: pointer;
  }
  .big-img-box {
    max-width: 90%;
    height: 7rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
  }
  .arrow {
    position: absolute;
    top: 51%;
    width: 0.4rem;
    height: 0.4rem;
    line-height: 0.45rem;
    text-align: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
  }
  .big-list-box {
    width: 100%;
    height: 1rem;
    background-color: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow-x: auto;
    overflow-y: hidden;
    ul {
      display: -webkit-box;
      li {
        // width: 62px;
        height: 0.62rem;
        // overflow: hidden;
        margin: 0.15rem 0.05rem 0;
        border: 4px solid #000;
        box-sizing: content-box;
        img {
          height: 0.62rem;
          margin: 0 auto;
        }
      }
      .active {
        border: 4px solid #4c98ff;
      }
    }
  }
  .left {
    position: relative;
    width: 1080px;
    height: 770px;
    // padding: 20px;
    // float: left;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .img {
      position: absolute;
      cursor: move;
      object-fit: contain;
    }
  }
}
.big-list-box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}
</style>
